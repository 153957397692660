import MuiPagination, { PaginationProps as MuiPaginationProps } from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import React from 'react';

const PreviousIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
    <path
      d="M8 11.5L6 7.5L8 3.5"
      stroke="#6C6C6C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
    <path
      d="M6 11.5L8 7.5L6 3.5"
      stroke="#6C6C6C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export interface PaginationProps extends MuiPaginationProps {
  count?: number;
  page?: number;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

export function Pagination({ count, page, onChange, ...props }: PaginationProps) {
  return (
    <MuiPagination
      className="bs-pagination"
      count={count}
      page={page}
      onChange={onChange}
      shape="rounded"
      {...props}
      renderItem={item => (
        <PaginationItem
          slots={{ previous: PreviousIcon, next: NextIcon }}
          {...item}
          sx={{
            fontSize: '1rem',
            color: '#A4A4A4',
            fontFamily: 'ploni, sans-serif',

            minWidth: '28px',
            height: '28px',
            padding: '0 4px',

            '&.Mui-selected': {
              color: '#404040',
              backgroundColor: 'transparent',
            },
          }}
        />
      )}
    />
  );
}
