import React, { ReactNode } from 'react';

export interface SelectItem {
	elId: string | number;
	value: string;
	img?: string;
	showImg?: boolean;
	_id?: string;
	name?: string;
	countryIsoCode?: string;
	dialCode?: number;
	symbol?: string;
	order?: number;
	count?: number;
	type?: number;
}

export interface SelectMenuProps {
	/**[required] list of items to select from */
	items?: SelectItem[];

	/**[required] the function will be executed when a usr clicks on a select item*/
	onItemSelect: (item: SelectItem) => void;

	/**[optional] select query */
	query?: any;

	/**[optional][default=true] make the element to be 100% of it's container width*/
	fillContainerWidth?: boolean;

	/**[optional] do something when the query changed */
	onQueryTextChange?: (queryText: string) => void;

	/**[optional][default=false] show images inside the menu items */
	showMenuImages?: boolean;

	/**[optional] define a different logic to render the menu item image, will be ignored if showMenuImages=false */
	menuItemImgRenderer?: (item: SelectItem) => ReactNode;
}

export function highlightText(text: string, query: string) {
	let lastIndex = 0;
	const words = query
		.split(/\s+/)
		.filter((word) => word.length > 0)
		.map(escapeRegExpChars);
	if (words.length === 0) {
		return [text];
	}
	const regexp = new RegExp(words.join('|'), 'gi');
	const tokens: React.ReactNode[] = [];
	while (true) {
		const match = regexp.exec(text);
		if (!match) {
			break;
		}
		const length = match[0].length;
		const before = text.slice(lastIndex, regexp.lastIndex - length);
		if (before.length > 0) {
			tokens.push(before);
		}
		lastIndex = regexp.lastIndex;
		tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
	}
	const rest = text.slice(lastIndex);
	if (rest.length > 0) {
		tokens.push(rest);
	}

	return tokens;
}

export function escapeRegExpChars(text: string) {
	return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}
