import { useState, useEffect } from 'react';

const MOBILE_BREAKPOINT: number = 991;

export const useIsMobile = () => {
	const [windowWidth, setWindowWidth] = useState<number>(0);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowWidth < MOBILE_BREAKPOINT;
};
