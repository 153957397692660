import React, { ReactNode, HTMLAttributes } from 'react';

import './toggleButton.scss';

export interface ToggleButtonProps extends HTMLAttributes<HTMLInputElement> {
	/**[optional][default=false] is checked*/
	checked?: boolean;

	/**[optional] on change function*/
	onChange?: (event?: any) => void;

	/**[optional] label text*/
	label?: ReactNode;

	/**[optional][default=false] is the input elentnt disabled*/
	disabled?: boolean;

	/**[optional][default=''] classname*/
	className?: string;
}

export function ToggleButton({
	checked = false,
	onChange,
	label,
	disabled,
	className = '',
	...props
}: ToggleButtonProps) {
	return (
		<div
			className={`toggle-button ${label ? 'toggle-has-label' : ''} ${className} ${
				disabled ? 'disabled-toggle' : ''
			}`}
		>
			<label className='toggle-container'>
				{label && <span className='toggle-button-text'>{label}</span>}
				<input {...props} type={'checkbox'} checked={checked} onChange={onChange} />
				<span className='toggle-frame'></span>
			</label>
		</div>
	);
}
