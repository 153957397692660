import { SelectItem } from '../..';

export const countryItems: SelectItem[] = [
	{
		name: 'Zimbabwe',
		img: 'https://flagcdn.com/h20/zw.png',
		value: '+263',
		elId: 'zw',
		dialCode: 263
	},
	{
		name: 'Western Sahara',
		img: 'https://flagcdn.com/h20/eh.png',
		value: '+212',
		elId: 'eh',
		dialCode: 212
	},
	{
		name: 'Zambia',
		img: 'https://flagcdn.com/h20/zm.png',
		value: '+260',
		elId: 'zm',
		dialCode: 260
	},
	{
		name: 'Yemen',
		img: 'https://flagcdn.com/h20/ye.png',
		value: '+967',
		elId: 'ye',
		dialCode: 967
	},
	{
		name: 'Vietnam',
		img: 'https://flagcdn.com/h20/vn.png',
		value: '+84',
		elId: 'vn',
		dialCode: 84
	},
	{
		name: 'Wallis and Futuna',
		img: 'https://flagcdn.com/h20/wf.png',
		value: '+681',
		elId: 'wf',
		dialCode: 681
	},
	{
		name: 'Venezuela',
		img: 'https://flagcdn.com/h20/ve.png',
		value: '+58',
		elId: 've',
		dialCode: 58
	},
	{
		name: 'Vatican',
		img: 'https://flagcdn.com/h20/va.png',
		value: '+379',
		elId: 'va',
		dialCode: 379
	},
	{
		name: 'Uruguay',
		img: 'https://flagcdn.com/h20/uy.png',
		value: '+598',
		elId: 'uy',
		dialCode: 598
	},
	{
		name: 'Vanuatu',
		img: 'https://flagcdn.com/h20/vu.png',
		value: '+678',
		elId: 'vu',
		dialCode: 678
	},
	{
		name: 'Uzbekistan',
		img: 'https://flagcdn.com/h20/uz.png',
		value: '+998',
		elId: 'uz',
		dialCode: 998
	},
	{
		name: 'United Kingdom',
		img: 'https://flagcdn.com/h20/gb.png',
		value: '+44',
		elId: 'gb',
		dialCode: 44
	},
	{
		name: 'United States',
		img: 'https://flagcdn.com/h20/us.png',
		value: '+1',
		elId: 'us',
		dialCode: 1
	},
	{
		name: 'United Arab Emirates',
		img: 'https://flagcdn.com/h20/ae.png',
		value: '+971',
		elId: 'ae',
		dialCode: 971
	},
	{
		name: 'Ukraine',
		img: 'https://flagcdn.com/h20/ua.png',
		value: '+380',
		elId: 'ua',
		dialCode: 380
	},
	{
		name: 'Uganda',
		img: 'https://flagcdn.com/h20/ug.png',
		value: '+256',
		elId: 'ug',
		dialCode: 256
	},
	{
		name: 'U.S. Virgin Islands',
		img: 'https://flagcdn.com/h20/vi.png',
		value: '+1-340',
		elId: 'vi',
		dialCode: 1340
	},
	{
		name: 'Tuvalu',
		img: 'https://flagcdn.com/h20/tv.png',
		value: '+688',
		elId: 'tv',
		dialCode: 688
	},
	{
		name: 'Turks and Caicos Islands',
		img: 'https://flagcdn.com/h20/tc.png',
		value: '+1-649',
		elId: 'tc',
		dialCode: 1649
	},
	{
		name: 'Turkmenistan',
		img: 'https://flagcdn.com/h20/tm.png',
		value: '+993',
		elId: 'tm',
		dialCode: 993
	},
	{
		name: 'Turkey',
		img: 'https://flagcdn.com/h20/tr.png',
		value: '+90',
		elId: 'tr',
		dialCode: 90
	},
	{
		name: 'Tunisia',
		img: 'https://flagcdn.com/h20/tn.png',
		value: '+216',
		elId: 'tn',
		dialCode: 261
	},
	{
		name: 'Trinidad and Tobago',
		img: 'https://flagcdn.com/h20/tt.png',
		value: '+1-868',
		elId: 'tt',
		dialCode: 1686
	},
	{
		name: 'Tonga',
		img: 'https://flagcdn.com/h20/to.png',
		value: '+676',
		elId: 'to',
		dialCode: 676
	},
	{
		name: 'Tokelau',
		img: 'https://flagcdn.com/h20/tk.png',
		value: '+690',
		elId: 'tk',
		dialCode: 690
	},
	{
		name: 'Togo',
		img: 'https://flagcdn.com/h20/tg.png',
		value: '+228',
		elId: 'tg',
		dialCode: 228
	},
	{
		name: 'Thailand',
		img: 'https://flagcdn.com/h20/th.png',
		value: '+66',
		elId: 'th',
		dialCode: 66
	},
	{
		name: 'Tanzania',
		img: 'https://flagcdn.com/h20/tz.png',
		value: '+255',
		elId: 'tz',
		dialCode: 225
	},
	{
		name: 'Tajikistan',
		img: 'https://flagcdn.com/h20/tj.png',
		value: '+992',
		elId: 'tj',
		dialCode: 992
	},
	{
		name: 'Taiwan',
		img: 'https://flagcdn.com/h20/tw.png',
		value: '+886',
		elId: 'tw',
		dialCode: 886
	},
	{
		name: 'Syria',
		img: 'https://flagcdn.com/h20/sy.png',
		value: '+963',
		elId: 'sy',
		dialCode: 963
	},
	{
		name: 'Swaziland',
		img: 'https://flagcdn.com/h20/sz.png',
		value: '+268',
		elId: 'sz',
		dialCode: 268
	},
	{
		name: 'Switzerland',
		img: 'https://flagcdn.com/h20/ch.png',
		value: '+41',
		elId: 'ch',
		dialCode: 41
	},
	{
		name: 'Sweden',
		img: 'https://flagcdn.com/h20/se.png',
		value: '+46',
		elId: 'se',
		dialCode: 46
	},
	{
		name: 'Svalbard and Jan Mayen',
		img: 'https://flagcdn.com/h20/sj.png',
		value: '+47',
		elId: 'sj',
		dialCode: 47
	},
	{
		name: 'Suriname',
		img: 'https://flagcdn.com/h20/sr.png',
		value: '+597',
		elId: 'sr',
		dialCode: 597
	},
	{
		name: 'Sudan',
		img: 'https://flagcdn.com/h20/sd.png',
		value: '+249',
		elId: 'sd',
		dialCode: 249
	},
	{
		name: 'Sri Lanka',
		img: 'https://flagcdn.com/h20/lk.png',
		value: '+94',
		elId: 'lk',
		dialCode: 94
	},
	{
		name: 'Spain',
		img: 'https://flagcdn.com/h20/es.png',
		value: '+34',
		elId: 'es',
		dialCode: 34
	},
	{
		name: 'South Korea',
		img: 'https://flagcdn.com/h20/kr.png',
		value: '+82',
		elId: 'kr',
		dialCode: 82
	},
	{
		name: 'South Sudan',
		img: 'https://flagcdn.com/h20/ss.png',
		value: '+211',
		elId: 'ss',
		dialCode: 211
	},
	{
		name: 'Slovenia',
		img: 'https://flagcdn.com/h20/si.png',
		value: '+386',
		elId: 'si',
		dialCode: 286
	},
	{
		name: 'South Africa',
		img: 'https://flagcdn.com/h20/za.png',
		value: '+27',
		elId: 'za',
		dialCode: 27
	},
	{
		name: 'Somalia',
		img: 'https://flagcdn.com/h20/so.png',
		value: '+252',
		elId: 'so',
		dialCode: 252
	},
	{
		name: 'Solomon Islands',
		img: 'https://flagcdn.com/h20/sb.png',
		value: '+677',
		elId: 'sb',
		dialCode: 677
	},
	{
		name: 'Saudi Arabia',
		img: 'https://flagcdn.com/h20/sa.png',
		value: '+966',
		elId: 'sa',
		dialCode: 966
	},
	{
		name: 'Sint Maarten',
		img: 'https://flagcdn.com/h20/sx.png',
		value: '+1-721',
		elId: 'sx',
		dialCode: 1721
	},
	{
		name: 'Slovakia',
		img: 'https://flagcdn.com/h20/sk.png',
		value: '+421',
		elId: 'sk',
		dialCode: 421
	},
	{
		name: 'Senegal',
		img: 'https://flagcdn.com/h20/sn.png',
		value: '+221',
		elId: 'sn',
		dialCode: 221
	},
	{
		name: 'Samoa',
		img: 'https://flagcdn.com/h20/ws.png',
		value: '+685',
		elId: 'ws',
		dialCode: 685
	},
	{
		name: 'Singapore',
		img: 'https://flagcdn.com/h20/sg.png',
		value: '+65',
		elId: 'sg',
		dialCode: 65
	},
	{
		name: 'Sierra Leone',
		img: 'https://flagcdn.com/h20/sl.png',
		value: '+232',
		elId: 'sl',
		dialCode: 232
	},
	{
		name: 'Seychelles',
		img: 'https://flagcdn.com/h20/sc.png',
		value: '+248',
		elId: 'sc',
		dialCode: 248
	},
	{
		name: 'Serbia',
		img: 'https://flagcdn.com/h20/rs.png',
		value: '+381',
		elId: 'rs',
		dialCode: 381
	},
	{
		name: 'Saint Vincent and the Grenadines',
		img: 'https://flagcdn.com/h20/vc.png',
		value: '+1-784',
		elId: 'vc',
		dialCode: 1784
	},
	{
		name: 'Sao Tome and Principe',
		img: 'https://flagcdn.com/h20/st.png',
		value: '+239',
		elId: 'st',
		dialCode: 239
	},
	{
		name: 'San Marino',
		img: 'https://flagcdn.com/h20/sm.png',
		value: '+378',
		elId: 'sm',
		dialCode: 378
	},
	{
		name: 'Saint Martin',
		img: 'https://flagcdn.com/h20/mf.png',
		value: '+590',
		elId: 'mf',
		dialCode: 590
	},
	{
		name: 'Saint Pierre and Miquelon',
		img: 'https://flagcdn.com/h20/pm.png',
		value: '+508',
		elId: 'pm',
		dialCode: 508
	},
	{
		name: 'Saint Kitts and Nevis',
		img: 'https://flagcdn.com/h20/kn.png',
		value: '+1-869',
		elId: 'kn',
		dialCode: 1869
	},
	{
		name: 'Puerto Rico',
		img: 'https://flagcdn.com/h20/pr.png',
		value: '+1-787',
		elId: 'pr',
		dialCode: 1787
	},
	{
		name: 'Puerto Rico',
		img: 'https://flagcdn.com/h20/pr.png',
		value: '+1-939',
		elId: 'pr',
		dialCode: 1939
	},
	{
		name: 'Saint Lucia',
		img: 'https://flagcdn.com/h20/lc.png',
		value: '+1-758',
		elId: 'lc',
		dialCode: 1758
	},
	{
		name: 'Saint Helena',
		img: 'https://flagcdn.com/h20/sh.png',
		value: '+290',
		elId: 'sh',
		dialCode: 290
	},
	{
		name: 'Saint Barthelemy',
		img: 'https://flagcdn.com/h20/bl.png',
		value: '+590',
		elId: 'bl',
		dialCode: 590
	},
	{
		name: 'Rwanda',
		img: 'https://flagcdn.com/h20/rw.png',
		value: '+250',
		elId: 'rw',
		dialCode: 250
	},
	{
		name: 'Russia',
		img: 'https://flagcdn.com/h20/ru.png',
		value: '+7',
		elId: 'ru',
		dialCode: 7
	},
	{
		name: 'Romania',
		img: 'https://flagcdn.com/h20/ro.png',
		value: '+40',
		elId: 'ro',
		dialCode: 40
	},
	{
		name: 'Reunion',
		img: 'https://flagcdn.com/h20/re.png',
		value: '+262',
		elId: 're',
		dialCode: 262
	},
	{
		name: 'Republic of the Congo',
		img: 'https://flagcdn.com/h20/cg.png',
		value: '+242',
		elId: 'cg',
		dialCode: 242
	},
	{
		name: 'Qatar',
		img: 'https://flagcdn.com/h20/qa.png',
		value: '+974',
		elId: 'qa',
		dialCode: 974
	},
	{
		name: 'Portugal',
		img: 'https://flagcdn.com/h20/pt.png',
		value: '+351',
		elId: 'pt',
		dialCode: 351
	},
	{
		name: 'Poland',
		img: 'https://flagcdn.com/h20/pl.png',
		value: '+48',
		elId: 'pl',
		dialCode: 48
	},
	{
		name: 'Philippines',
		img: 'https://flagcdn.com/h20/ph.png',
		value: '+63',
		elId: 'ph',
		dialCode: 63
	},
	{
		name: 'Pitcairn',
		img: 'https://flagcdn.com/h20/pn.png',
		value: '+64',
		elId: 'pn',
		dialCode: 64
	},
	{
		name: 'Namibia',
		img: 'https://flagcdn.com/h20/na.png',
		value: '+264',
		elId: 'na',
		dialCode: 264
	},
	{
		name: 'Peru',
		img: 'https://flagcdn.com/h20/pe.png',
		value: '+51',
		elId: 'pe',
		dialCode: 51
	},
	{
		name: 'Paraguay',
		img: 'https://flagcdn.com/h20/py.png',
		value: '+595',
		elId: 'py',
		dialCode: 595
	},
	{
		name: 'Nepal',
		img: 'https://flagcdn.com/h20/np.png',
		value: '+977',
		elId: 'np',
		dialCode: 977
	},
	{
		name: 'Pakistan',
		img: 'https://flagcdn.com/h20/pk.png',
		value: '+92',
		elId: 'pk',
		dialCode: 92
	},
	{
		name: 'Papua New Guinea',
		img: 'https://flagcdn.com/h20/pg.png',
		value: '+675',
		elId: 'pg',
		dialCode: 675
	},
	{
		name: 'Panama',
		img: 'https://flagcdn.com/h20/pa.png',
		value: '+507',
		elId: 'pa',
		dialCode: 507
	},
	{
		name: 'Palestine',
		img: 'https://flagcdn.com/h20/ps.png',
		value: '+970',
		elId: 'ps',
		dialCode: 970
	},
	{
		name: 'Palau',
		img: 'https://flagcdn.com/h20/pw.png',
		value: '+680',
		elId: 'pw',
		dialCode: 680
	},
	{
		name: 'Northern Mariana Islands',
		img: 'https://flagcdn.com/h20/mp.png',
		value: '+1670',
		elId: 'mp',
		dialCode: 1670
	},
	{
		name: 'Norway',
		img: 'https://flagcdn.com/h20/no.png',
		value: '+47',
		elId: 'no',
		dialCode: 47
	},
	{
		name: 'Oman',
		img: 'https://flagcdn.com/h20/om.png',
		value: '+968',
		elId: 'om',
		dialCode: 968
	},
	{
		name: 'Niue',
		img: 'https://flagcdn.com/h20/nu.png',
		value: '+683',
		elId: 'nu',
		dialCode: 683
	},
	{
		name: 'North Korea',
		img: 'https://flagcdn.com/h20/kp.png',
		value: '+850',
		elId: 'kp',
		dialCode: 850
	},
	{
		name: 'New Caledonia',
		img: 'https://flagcdn.com/h20/nc.png',
		value: '+687',
		elId: 'nc',
		dialCode: 687
	},
	{
		name: 'Nigeria',
		img: 'https://flagcdn.com/h20/ng.png',
		value: '+234',
		elId: 'ng',
		dialCode: 234
	},
	{
		name: 'Niger',
		img: 'https://flagcdn.com/h20/ne.png',
		value: '+227',
		elId: 'ne',
		dialCode: 227
	},
	{
		name: 'Nicaragua',
		img: 'https://flagcdn.com/h20/ni.png',
		value: '+505',
		elId: 'ni',
		dialCode: 505
	},
	{
		name: 'New Zealand',
		img: 'https://flagcdn.com/h20/nz.png',
		value: '+64',
		elId: 'nz',
		dialCode: 64
	},
	{
		name: 'Nauru',
		img: 'https://flagcdn.com/h20/nr.png',
		value: '+674',
		elId: 'nr',
		dialCode: 674
	},
	{
		name: 'Netherlands',
		img: 'https://flagcdn.com/h20/nl.png',
		value: '+31',
		elId: 'nl',
		dialCode: 31
	},
	{
		name: 'Monaco',
		img: 'https://flagcdn.com/h20/mc.png',
		value: '+377',
		elId: 'mc',
		dialCode: 377
	},
	{
		name: 'Mozambique',
		img: 'https://flagcdn.com/h20/mz.png',
		value: '+258',
		elId: 'mz',
		dialCode: 258
	},
	{
		name: 'Myanmar',
		img: 'https://flagcdn.com/h20/mm.png',
		value: '+95',
		elId: 'mm',
		dialCode: 95
	},
	{
		name: 'Montserrat',
		img: 'https://flagcdn.com/h20/ms.png',
		value: '+1-664',
		elId: 'ms',
		dialCode: 1664
	},
	{
		name: 'Morocco',
		img: 'https://flagcdn.com/h20/ma.png',
		value: '+212',
		elId: 'ma',
		dialCode: 212
	},
	{
		name: 'Mongolia',
		img: 'https://flagcdn.com/h20/mn.png',
		value: '+976',
		elId: 'mn',
		dialCode: 976
	},
	{
		name: 'Montenegro',
		img: 'https://flagcdn.com/h20/me.png',
		value: '+382',
		elId: 'me',
		dialCode: 382
	},
	{
		name: 'Moldova',
		img: 'https://flagcdn.com/h20/md.png',
		value: '+373',
		elId: 'md',
		dialCode: 373
	},
	{
		name: 'Micronesia',
		img: 'https://flagcdn.com/h20/fm.png',
		value: '+691',
		elId: 'fm',
		dialCode: 691
	},
	{
		name: 'Mexico',
		img: 'https://flagcdn.com/h20/mx.png',
		value: '+52',
		elId: 'mx',
		dialCode: 52
	},
	{
		name: 'Kyrgyzstan',
		img: 'https://flagcdn.com/h20/kg.png',
		value: '+996',
		elId: 'kg',
		dialCode: 996
	},
	{
		name: 'Mayotte',
		img: 'https://flagcdn.com/h20/ty.png',
		value: '+262',
		elId: 'ty',
		dialCode: 262
	},
	{
		name: 'Mauritius',
		img: 'https://flagcdn.com/h20/mu.png',
		value: '+230',
		elId: 'mu',
		dialCode: 230
	},
	{
		name: 'Maldives',
		img: 'https://flagcdn.com/h20/mv.png',
		value: '+960',
		elId: 'mv',
		dialCode: 960
	},
	{
		name: 'Mauritania',
		img: 'https://flagcdn.com/h20/mr.png',
		value: '+222',
		elId: 'mr',
		dialCode: 221
	},
	{
		name: 'Marshall Islands',
		img: 'https://flagcdn.com/h20/mh.png',
		value: '+692',
		elId: 'mh',
		dialCode: 692
	},
	{
		name: 'Malta',
		img: 'https://flagcdn.com/h20/mt.png',
		value: '+356',
		elId: 'mt',
		dialCode: 356
	},
	{
		name: 'Mali',
		img: 'https://flagcdn.com/h20/ml.png',
		value: '+223',
		elId: 'ml',
		dialCode: 223
	},
	{
		name: 'Malaysia',
		img: 'https://flagcdn.com/h20/my.png',
		value: '+60',
		elId: 'my',
		dialCode: 60
	},
	{
		name: 'Malawi',
		img: 'https://flagcdn.com/h20/mw.png',
		value: '+265',
		elId: 'mw',
		dialCode: 265
	},
	{
		name: 'Madagascar',
		img: 'https://flagcdn.com/h20/mg.png',
		value: '+261',
		elId: 'mg',
		dialCode: 261
	},
	{
		name: 'Macedonia',
		img: 'https://flagcdn.com/h20/mk.png',
		value: '+389',
		elId: 'mk',
		dialCode: 389
	},
	{
		name: 'Macau',
		img: 'https://flagcdn.com/h20/mo.png',
		value: '+853',
		elId: 'mo',
		dialCode: 853
	},
	{
		name: 'Luxembourg',
		img: 'https://flagcdn.com/h20/lu.png',
		value: '+352',
		elId: 'lu',
		dialCode: 352
	},
	{
		name: 'Lithuania',
		img: 'https://flagcdn.com/h20/lt.png',
		value: '+370',
		elId: 'lt',
		dialCode: 370
	},
	{
		name: 'Liechtenstein',
		img: 'https://flagcdn.com/h20/li.png',
		value: '+423',
		elId: 'li',
		dialCode: 423
	},
	{
		name: 'Libya',
		img: 'https://flagcdn.com/h20/ly.png',
		value: '+218',
		elId: 'ly',
		dialCode: 218
	},
	{
		name: 'Liberia',
		img: 'https://flagcdn.com/h20/lr.png',
		value: '+231',
		elId: 'lr',
		dialCode: 231
	},
	{
		name: 'Lesotho',
		img: 'https://flagcdn.com/h20/ls.png',
		value: '+266',
		elId: 'ls',
		dialCode: 266
	},
	{
		name: 'Lebanon',
		img: 'https://flagcdn.com/h20/lb.png',
		value: '+961',
		elId: 'lb',
		dialCode: 961
	},
	{
		name: 'Latvia',
		img: 'https://flagcdn.com/h20/lv.png',
		value: '+371',
		elId: 'lv',
		dialCode: 371
	},
	{
		name: 'Laos',
		img: 'https://flagcdn.com/h20/la.png',
		value: '+856',
		elId: 'la',
		dialCode: 856
	},
	{
		name: 'Kuwait',
		img: 'https://flagcdn.com/h20/kw.png',
		value: '+965',
		elId: 'kw',
		dialCode: 965
	},
	{
		name: 'Kosovo',
		img: 'https://flagcdn.com/h20/xk.png',
		value: '+383',
		elId: 'xk',
		dialCode: 383
	},
	{
		name: 'Kiribati',
		img: 'https://flagcdn.com/h20/ki.png',
		value: '+686',
		elId: 'ki',
		dialCode: 868
	},
	{
		name: 'Kenya',
		img: 'https://flagcdn.com/h20/ke.png',
		value: '+254',
		elId: 'ke',
		dialCode: 254
	},
	{
		name: 'Kazakhstan',
		img: 'https://flagcdn.com/h20/kz.png',
		value: '+7',
		elId: 'kz',
		dialCode: 7
	},
	{
		name: 'Jordan',
		img: 'https://flagcdn.com/h20/jo.png',
		value: '+962',
		elId: 'jo',
		dialCode: 962
	},
	{
		name: 'Guinea-Bissau',
		img: 'https://flagcdn.com/h20/gw.png',
		value: '+245',
		elId: 'gw',
		dialCode: 245
	},
	{
		name: 'Estonia',
		img: 'https://flagcdn.com/h20/ee.png',
		value: '+372',
		elId: 'ee',
		dialCode: 372
	},
	{
		name: 'Eritrea',
		img: 'https://flagcdn.com/h20/er.png',
		value: '+291',
		elId: 'er',
		dialCode: 291
	},
	{
		name: 'El Salvador',
		img: 'https://flagcdn.com/h20/sv.png',
		value: '+503',
		elId: 'sv',
		dialCode: 503
	},
	{
		name: 'Ivory Coast',
		img: 'https://flagcdn.com/h20/ci.png',
		value: '+225',
		elId: 'ci',
		dialCode: 225
	},
	{
		name: 'Egypt',
		img: 'https://flagcdn.com/h20/eg.png',
		value: '+20',
		elId: 'eg',
		dialCode: 20
	},
	{
		name: 'Equatorial Guinea',
		img: 'https://flagcdn.com/h20/gq.png',
		value: '+240',
		elId: 'gq',
		dialCode: 240
	},
	{
		name: 'Jersey',
		img: 'https://flagcdn.com/h20/je.png',
		value: '+44-1534',
		elId: 'je',
		dialCode: 441534
	},
	{
		name: 'Japan',
		img: 'https://flagcdn.com/h20/jp.png',
		value: '+81',
		elId: 'jp',
		dialCode: 81
	},
	{
		name: 'Jamaica',
		img: 'https://flagcdn.com/h20/jm.png',
		value: '+1-876',
		elId: 'jm',
		dialCode: 1876
	},
	{
		name: 'Isle of Man',
		img: 'https://flagcdn.com/h20/im.png',
		value: '+44-1624',
		elId: 'im',
		dialCode: 441624
	},
	{
		name: 'Italy',
		img: 'https://flagcdn.com/h20/it.png',
		value: '+39',
		elId: 'it',
		dialCode: 39
	},
	{
		name: 'Israel',
		img: 'https://flagcdn.com/h20/il.png',
		value: '+972',
		elId: 'il',
		dialCode: 972
	},
	{
		name: 'Ireland',
		img: 'https://flagcdn.com/h20/ie.png',
		value: '+353',
		elId: 'ie',
		dialCode: 353
	},
	{
		name: 'Iraq',
		img: 'https://flagcdn.com/h20/iq.png',
		value: '+964',
		elId: 'iq',
		dialCode: 964
	},
	{
		name: 'Iran',
		img: 'https://flagcdn.com/h20/ir.png',
		value: '+98',
		elId: 'ir',
		dialCode: 98
	},
	{
		name: 'Indonesia',
		img: 'https://flagcdn.com/h20/id.png',
		value: '+62',
		elId: 'id',
		dialCode: 62
	},
	{
		name: 'India',
		img: 'https://flagcdn.com/h20/in.png',
		value: '+91',
		elId: 'in',
		dialCode: 91
	},
	{
		name: 'Iceland',
		img: 'https://flagcdn.com/h20/is.png',
		value: '+354',
		elId: 'is',
		dialCode: 354
	},
	{
		name: 'Hungary',
		img: 'https://flagcdn.com/h20/hu.png',
		value: '+36',
		elId: 'hu',
		dialCode: 36
	},
	{
		name: 'Hong Kong',
		img: 'https://flagcdn.com/h20/hk.png',
		value: '+852',
		elId: 'hk',
		dialCode: 852
	},
	{
		name: 'Honduras',
		img: 'https://flagcdn.com/h20/hn.png',
		value: '+504',
		elId: 'hn',
		dialCode: 504
	},
	{
		name: 'Haiti',
		img: 'https://flagcdn.com/h20/ht.png',
		value: '+509',
		elId: 'ht',
		dialCode: 509
	},
	{
		name: 'Cameroon',
		img: 'https://flagcdn.com/h20/cm.png',
		value: '+237',
		elId: 'cm',
		dialCode: 237
	},
	{
		name: 'Guyana',
		img: 'https://flagcdn.com/h20/gy.png',
		value: '+592',
		elId: 'gy',
		dialCode: 592
	},
	{
		name: 'Bahamas',
		img: 'https://flagcdn.com/h20/bs.png',
		value: '+1-242',
		elId: 'bs',
		dialCode: 1242
	},
	{
		name: 'Guinea',
		img: 'https://flagcdn.com/h20/gn.png',
		value: '+224',
		elId: 'gn',
		dialCode: 224
	},
	{
		name: 'Guernsey',
		img: 'https://flagcdn.com/h20/gg.png',
		value: '+44-1481',
		elId: 'gg',
		dialCode: 441481
	},
	{
		name: 'Guatemala',
		img: 'https://flagcdn.com/h20/gt.png',
		value: '+502',
		elId: 'gt',
		dialCode: 502
	},
	{
		name: 'Guam',
		img: 'https://flagcdn.com/h20/gu.png',
		value: '+1-671',
		elId: 'gu',
		dialCode: 1671
	},
	{
		name: 'Grenada',
		img: 'https://flagcdn.com/h20/gd.png',
		value: '+1-473',
		elId: 'gd',
		dialCode: 1473
	},
	{
		name: 'Greenland',
		img: 'https://flagcdn.com/h20/gl.png',
		value: '+299',
		elId: 'gl',
		dialCode: 299
	},
	{
		name: 'Greece',
		img: 'https://flagcdn.com/h20/gr.png',
		value: '+30',
		elId: 'gr',
		dialCode: 30
	},
	{
		name: 'Gibraltar',
		img: 'https://flagcdn.com/h20/gi.png',
		value: '+350',
		elId: 'gi',
		dialCode: 350
	},
	{
		name: 'Ghana',
		img: 'https://flagcdn.com/h20/gh.png',
		value: '+233',
		elId: 'gh',
		dialCode: 233
	},
	{
		name: 'Germany',
		img: 'https://flagcdn.com/h20/de.png',
		value: '+49',
		elId: 'de',
		dialCode: 49
	},
	{
		name: 'Georgia',
		img: 'https://flagcdn.com/h20/ge.png',
		value: '+995',
		elId: 'ge',
		dialCode: 995
	},
	{
		name: 'Gambia',
		img: 'https://flagcdn.com/h20/gm.png',
		value: '+220',
		elId: 'gm',
		dialCode: 220
	},
	{
		name: 'Gabon',
		img: 'https://flagcdn.com/h20/ga.png',
		value: '+241',
		elId: 'ga',
		dialCode: 241
	},
	{
		name: 'French Polynesia',
		img: 'https://flagcdn.com/h20/pe.png',
		value: '+689',
		elId: 'pe',
		dialCode: 689
	},
	{
		name: 'France',
		img: 'https://flagcdn.com/h20/fr.png',
		value: '+33',
		elId: 'fr',
		dialCode: 33
	},
	{
		name: 'Finland',
		img: 'https://flagcdn.com/h20/fi.png',
		value: '+358',
		elId: 'fi',
		dialCode: 358
	},
	{
		name: 'Fiji',
		img: 'https://flagcdn.com/h20/fj.png',
		value: '+679',
		elId: 'fj',
		dialCode: 679
	},
	{
		name: 'Faroe Islands',
		img: 'https://flagcdn.com/h20/fo.png',
		value: '+298',
		elId: 'fo',
		dialCode: 298
	},
	{
		name: 'Falkland Islands',
		img: 'https://flagcdn.com/h20/fk.png',
		value: '+500',
		elId: 'fk',
		dialCode: 500
	},
	{
		name: 'Ethiopia',
		img: 'https://flagcdn.com/h20/et.png',
		value: '+251',
		elId: 'et',
		dialCode: 251
	},
	{
		name: 'Chad',
		img: 'https://flagcdn.com/h20/td.png',
		value: '+235',
		elId: 'td',
		dialCode: 235
	},
	{
		name: 'Central African Republic',
		img: 'https://flagcdn.com/h20/cf.png',
		value: '+236',
		elId: 'cf',
		dialCode: 236
	},
	{
		name: 'Cayman Islands',
		img: 'https://flagcdn.com/h20/ky.png',
		value: '+1-345',
		elId: 'ky',
		dialCode: 1345
	},
	{
		name: 'Cape Verde',
		img: 'https://flagcdn.com/h20/cv.png',
		value: '+238',
		elId: 'cv',
		dialCode: 238
	},
	{
		name: 'Ecuador',
		img: 'https://flagcdn.com/h20/ec.png',
		value: '+593',
		elId: 'ec',
		dialCode: 593
	},
	{
		name: 'East Timor',
		img: 'https://flagcdn.com/h20/tl.png',
		value: '+670',
		elId: 'tl',
		dialCode: 670
	},
	{
		name: 'Dominican Republic',
		img: 'https://flagcdn.com/h20/do.png',
		value: '+1-809',
		elId: 'do',
		dialCode: 1809
	},
	{
		name: 'Dominican Republic',
		img: 'https://flagcdn.com/h20/do.png',
		value: '+1-829',
		elId: 'do',
		dialCode: 1829
	},
	{
		name: 'Dominican Republic',
		img: 'https://flagcdn.com/h20/do.png',
		value: '+1-849',
		elId: 'do',
		dialCode: 1849
	},
	{
		name: 'Dominica',
		img: 'https://flagcdn.com/h20/dm.png',
		value: '+1-767',
		elId: 'dm',
		dialCode: 1767
	},
	{
		name: 'Djibouti',
		img: 'https://flagcdn.com/h20/dj.png',
		value: '+253',
		elId: 'dj',
		dialCode: 253
	},
	{
		name: 'Democratic Republic of the Congo',
		img: 'https://flagcdn.com/h20/cd.png',
		value: '+243',
		elId: 'cd',
		dialCode: 243
	},
	{
		name: 'Denmark',
		img: 'https://flagcdn.com/h20/dk.png',
		value: '+45',
		elId: 'dk',
		dialCode: 45
	},
	{
		name: 'Czech Republic',
		img: 'https://flagcdn.com/h20/cz.png',
		value: '+420',
		elId: 'cz',
		dialCode: 420
	},
	{
		name: 'Croatia',
		img: 'https://flagcdn.com/h20/hr.png',
		value: '+385',
		elId: 'hr',
		dialCode: 385
	},
	{
		name: 'Cyprus',
		img: 'https://flagcdn.com/h20/cy.png',
		value: '+357',
		elId: 'cy',
		dialCode: 357
	},
	{
		name: 'Curacao',
		img: 'https://flagcdn.com/h20/cw.png',
		value: '+599',
		elId: 'cw',
		dialCode: 599
	},
	{
		name: 'Cuba',
		img: 'https://flagcdn.com/h20/cu.png',
		value: '+53',
		elId: 'cu',
		dialCode: 53
	},
	{
		name: 'Cambodia',
		img: 'https://flagcdn.com/h20/kh.png',
		value: '+855',
		elId: 'kh',
		dialCode: 855
	},
	{
		name: 'Costa Rica',
		img: 'https://flagcdn.com/h20/cr.png',
		value: '+506',
		elId: 'cr',
		dialCode: 506
	},
	{
		name: 'Cook Islands',
		img: 'https://flagcdn.com/h20/ck.png',
		value: '+682',
		elId: 'ck',
		dialCode: 682
	},
	{
		name: 'Comoros',
		img: 'https://flagcdn.com/h20/km.png',
		value: '+269',
		elId: 'km',
		dialCode: 269
	},
	{
		name: 'Colombia',
		img: 'https://flagcdn.com/h20/co.png',
		value: '+57',
		elId: 'co',
		dialCode: 57
	},
	{
		name: 'China',
		img: 'https://flagcdn.com/h20/cn.png',
		value: '+86',
		elId: 'cn',
		dialCode: 86
	},
	{
		name: 'Chile',
		img: 'https://flagcdn.com/h20/cl.png',
		value: '+56',
		elId: 'cl',
		dialCode: 56
	},
	{
		name: 'Canada',
		img: 'https://flagcdn.com/h20/ca.png',
		value: '+1',
		elId: 'ca',
		dialCode: 1
	},
	{
		name: 'Burundi',
		img: 'https://flagcdn.com/h20/bi.png',
		value: '+257',
		elId: 'bi',
		dialCode: 257
	},
	{
		name: 'Barbados',
		img: 'https://flagcdn.com/h20/bb.png',
		value: '+1-246',
		elId: 'brb',
		dialCode: 1246
	},
	{
		name: 'Burkina Faso',
		img: 'https://flagcdn.com/h20/bf.png',
		value: '+226',
		elId: 'bf',
		dialCode: 226
	},
	{
		name: 'Bahrain',
		img: 'https://flagcdn.com/h20/bh.png',
		value: '+973',
		elId: 'bh',
		dialCode: 973
	},
	{
		name: 'Bangladesh',
		img: 'https://flagcdn.com/h20/bd.png',
		value: '+880',
		elId: 'bd',
		dialCode: 880
	},
	{
		name: 'Azerbaijan',
		img: 'https://flagcdn.com/h20/az.png',
		value: '+994',
		elId: 'az',
		dialCode: 994
	},
	{
		name: 'Bulgaria',
		img: 'https://flagcdn.com/h20/bg.png',
		value: '+359',
		elId: 'bg',
		dialCode: 359
	},
	{
		name: 'Brunei',
		img: 'https://flagcdn.com/h20/bn.png',
		value: '+673',
		elId: 'bn',
		dialCode: 673
	},
	{
		name: 'British Virgin Islands',
		img: 'https://flagcdn.com/h20/vg.png',
		value: '+1-284',
		elId: 'vg',
		dialCode: 1284
	},
	{
		name: 'British Indian Ocean Territory',
		img: 'https://flagcdn.com/h20/io.png',
		value: '+246',
		elId: 'io',
		dialCode: 246
	},
	{
		name: 'Brazil',
		img: 'https://flagcdn.com/h20/br.png',
		value: '+55',
		elId: 'br',
		dialCode: 55
	},
	{
		name: 'Botswana',
		img: 'https://flagcdn.com/h20/bw.png',
		value: '+267',
		elId: 'bw',
		dialCode: 267
	},
	{
		name: 'Bosnia and Herzegovina',
		img: 'https://flagcdn.com/h20/ba.png',
		value: '+387',
		elId: 'ba',
		dialCode: 387
	},
	{
		name: 'Bolivia',
		img: 'https://flagcdn.com/h20/bo.png',
		value: '+591',
		elId: 'bo',
		dialCode: 591
	},
	{
		name: 'Anguilla',
		img: 'https://flagcdn.com/h20/ai.png',
		value: '+1-264',
		elId: 'ai',
		dialCode: 1264
	},
	{
		name: 'Belgium',
		img: 'https://flagcdn.com/h20/be.png',
		value: '+32',
		elId: 'be',
		dialCode: 32
	},
	{
		name: 'Bhutan',
		img: 'https://flagcdn.com/h20/bt.png',
		value: '+975',
		elId: 'bt',
		dialCode: 975
	},
	{
		name: 'Bermuda',
		img: 'https://flagcdn.com/h20/bm.png',
		value: '+1-441',
		elId: 'bm',
		dialCode: 1441
	},
	{
		name: 'Benin',
		img: 'https://flagcdn.com/h20/bj.png',
		value: '+229',
		elId: 'bj',
		dialCode: 229
	},
	{
		name: 'Belize',
		img: 'https://flagcdn.com/h20/bz.png',
		value: '+501',
		elId: 'bz',
		dialCode: 501
	},
	{
		name: 'Austria',
		img: 'https://flagcdn.com/h20/at.png',
		value: '+43',
		elId: 'at',
		dialCode: 43
	},
	{
		name: 'Belarus',
		img: 'https://flagcdn.com/h20/by.png',
		value: '+375',
		elId: 'by',
		dialCode: 375
	},
	{
		name: 'Australia',
		img: 'https://flagcdn.com/h20/au.png',
		value: '+61',
		elId: 'au',
		dialCode: 61
	},
	{
		name: 'Armenia',
		img: 'https://flagcdn.com/h20/am.png',
		value: '+374',
		elId: 'am',
		dialCode: 374
	},
	{
		name: 'Aruba',
		img: 'https://flagcdn.com/h20/aw.png',
		value: '+297',
		elId: 'aw',
		dialCode: 297
	},
	{
		name: 'Argentina',
		img: 'https://flagcdn.com/h20/ar.png',
		value: '+54',
		elId: 'ar',
		dialCode: 54
	},
	{
		name: 'Antigua and Barbuda',
		img: 'https://flagcdn.com/h20/ag.png',
		value: '+1-268',
		elId: 'ag',
		dialCode: 1268
	},
	{
		name: 'Antarctica',
		img: 'https://flagcdn.com/h20/aq.png',
		value: '+672',
		elId: 'aq',
		dialCode: 672
	},
	{
		name: 'Andorra',
		img: 'https://flagcdn.com/h20/ad.png',
		value: '+376',
		elId: 'ad',
		dialCode: 376
	},
	{
		name: 'Angola',
		img: 'https://flagcdn.com/h20/ao.png',
		value: '+244',
		elId: 'ao',
		dialCode: 244
	},
	{
		name: 'American Samoa',
		img: 'https://flagcdn.com/h20/as.png',
		value: '+1-684',
		elId: 'as',
		dialCode: 1684
	},
	{
		name: 'Algeria',
		img: 'https://flagcdn.com/h20/dz.png',
		value: '+213',
		elId: 'dz',
		dialCode: 213
	},
	{
		name: 'Afghanistan',
		img: 'https://flagcdn.com/h20/af.png',
		value: '+93',
		elId: 'af',
		dialCode: 93
	},
	{
		name: 'Albania',
		img: 'https://flagcdn.com/h20/al.png',
		value: '+355',
		elId: 'al',
		dialCode: 355
	}
];
