import { FormHelperText } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import React from 'react';
import { CheckIcon, CheckSquareIcon, CheckedIcon, CheckedSquareIcon } from '../svg';

export interface CheckboxProps extends FormControlLabelProps {
  errorMessage?: string;
  iconType?: 'circle' | 'square';
}

export function Checkbox({
  label,
  errorMessage = '',
  iconType = 'circle',
  ...props
}: CheckboxProps) {
  const checkIcon = iconType === 'circle' ? <CheckIcon /> : <CheckSquareIcon />;
  const checkedIcon = iconType === 'circle' ? <CheckedIcon /> : <CheckedSquareIcon />;

  return (
    <div className="checkbox">
      <FormControlLabel
        label={label}
        {...props}
        control={<MuiCheckbox checkedIcon={checkedIcon} icon={checkIcon} />}
        sx={{
          margin: 0,
          // alignItems: 'flex-start',
          gap: '12px',

          '.MuiFormControlLabel-label': {
            fontFamily: 'ploni, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            color: '#404040',
            letterSpacing: 'normal',
          },

          '.MuiCheckbox-root': {
            padding: 0,
          },

          '& + .MuiFormHelperText-root.Mui-error': {
            fontFamily: 'ploni, sans-serif',
            color: '#F00',
            margin: '4px 0 0',
            textAlign: 'inherit',
            fontSize: '12px',
          },
        }}
      />

      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
}
