import { ClearRounded } from '@mui/icons-material';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import React, { ReactNode } from 'react';
import InputLabel from './InputLabel';
import RTL from './RTL';

export interface TextFieldProps extends MuiTextFieldProps<'outlined'> {
  label?: string;
  allowClear?: boolean;
  onClear?: () => void;
  direction?: 'ltr' | 'rtl';
  animatedLabel?: boolean;
  readonly?: boolean;
  rightIcon?: ReactNode;
}

export function TextField({
  label,
  value,
  onClear,
  allowClear,
  InputProps: externalInputProps,
  direction = 'ltr',
  animatedLabel = false,
  readonly = false,
  rightIcon,
  disabled,
  ...rest
}: TextFieldProps) {
  if (readonly) {
    return (
      <div className="text-field">
        <InputLabel disabled={disabled}>{label}</InputLabel>

        {typeof value === 'string' && (
          <Typography
            component="span"
            fontSize="1rem"
            fontWeight={400}
            color="#6C6C6C"
            fontFamily="ploni, sans-serif"
          >
            {value || 'None'}
          </Typography>
        )}
      </div>
    );
  }

  const getIcon = () => {
    if (!!value && allowClear) {
      return (
        <InputAdornment position="end">
          <IconButton sx={{ p: '4px', mr: '-8px' }} onClick={onClear}>
            <ClearRounded fontSize="small" />
          </IconButton>
        </InputAdornment>
      );
    }

    if (rightIcon) {
      return <InputAdornment position="end">{rightIcon}</InputAdornment>;
    }

    return null;
  };

  return (
    <RTL direction={direction}>
      <div className="text-field" dir={direction}>
        <InputLabel disabled={disabled}>{label}</InputLabel>

        <MuiTextField
          fullWidth={true}
          size="small"
          value={value}
          label={animatedLabel ? label : null}
          disabled={disabled}
          InputProps={{
            sx: {
              fontFamily: 'ploni, sans-serif',
              fontSize: '1rem',
              color: '#404040',
              letterSpacing: 'normal',

              borderRadius: 3,
              backgroundColor: '#F8F8F8',

              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: 0,
              },

              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
                borderColor: '#307FF6',
              },

              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
                borderColor: '#F00',
              },

              '&.Mui-error + .MuiFormHelperText-root': {
                fontFamily: 'ploni, sans-serif',
                color: '#F00',
                margin: '4px 0 0',
                textAlign: 'inherit',
                fontSize: '12px',
              },
            },

            endAdornment: getIcon(),
            ...externalInputProps,
          }}
          InputLabelProps={{
            // shrink: true,
            sx: {
              fontFamily: 'ploni, sans-serif',
              color: '#A4A4A4',

              '&.Mui-focused': {
                color: '#307FF6',
              },
            },
          }}
          {...rest}
        />
      </div>
    </RTL>
  );
}
