import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { CircularProgress, Grow, Paper, PaperProps, Popper } from '@mui/material';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  autocompleteClasses,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import React, { forwardRef, useCallback, useState } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { v4 } from 'uuid';
import { CheckIcon, CheckSquareIcon, CheckedIcon, CheckedSquareIcon } from '../svg';
import InputLabel from './InputLabel';
import RTL from './RTL';

const PaperComponent = forwardRef<HTMLDivElement, PaperProps>((props, ref) => (
  <Grow in>
    <Paper {...props} ref={ref} />
  </Grow>
));

export type AutocompleteProps<T> = Partial<MuiAutocompleteProps<T, boolean, boolean, boolean>> & {
  options: T[];
  getOptionLabel: (option: T) => string;

  label?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  limitItems?: number;
  externalInputProps?: TextFieldProps;
  disableFilterOptions?: boolean;
  direction?: 'ltr' | 'rtl';
  animatedLabel?: boolean;
  hideCheckIcon?: boolean;
  addOnEnter?: boolean;
  searchAsYouType?: boolean;
  popperOffset?: number[];
};

const textStyles = {
  fontFamily: 'ploni, sans-serif',
  fontSize: '1rem',
  color: '#404040',
  letterSpacing: 'normal',
};

export function Autocomplete<T>({
  label,
  placeholder,
  multiple: externalMultiple,
  freeSolo,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  externalInputProps,
  limitItems,
  disableFilterOptions = false,
  direction = 'ltr',
  animatedLabel = false,
  hideCheckIcon = false,
  addOnEnter = false,
  loading = false,
  searchAsYouType = false,
  popperOffset,
  onInputChange,
  ...rest
}: AutocompleteProps<T>) {
  const multiple = (externalMultiple && !freeSolo) || addOnEnter;
  // const enableVirtualization = options.length > 300;

  const checkIcon = multiple ? <CheckIcon /> : <CheckSquareIcon />;
  const checkedIcon = multiple ? <CheckedIcon /> : <CheckedSquareIcon />;

  const [inputValue, setInputValue] = useState('');

  const getFilterOptions = () => {
    if (disableFilterOptions) return (options: T[]) => options;
    return createFilterOptions<T>({ limit: limitItems });
  };

  // Adapter for react-window
  const ListboxComponent = useCallback(
    React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
      const { children, ...other } = props;
      const itemData: React.ReactElement[] = [];

      (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
          itemData.push(item);
          itemData.push(...(item.children || []));
        }
      );

      const itemCount = itemData.length;
      const itemSize = 50;

      const getHeight = () => {
        if (itemCount > 8) return 8 * itemSize;
        return itemSize * itemCount;
      };

      const gridRef = useResetCache(itemCount);

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              direction={direction}
              itemData={itemData}
              height={getHeight()}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={() => itemSize}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    }),
    []
  );

  const hideNoOptionsOnInit = searchAsYouType && !inputValue && !options.length;

  return (
    <RTL direction={direction}>
      <div className="autocomplete" dir={direction}>
        <InputLabel>{label}</InputLabel>

        <MuiAutocomplete
          size="small"
          disablePortal
          multiple={multiple}
          freeSolo={freeSolo}
          disableCloseOnSelect={multiple}
          popupIcon={
            loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <KeyboardArrowDownRoundedIcon fontSize="small" />
            )
          }
          clearIcon={<ClearRoundedIcon fontSize="small" />}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          filterOptions={getFilterOptions()}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={v4()} aria-selected={!multiple ? props['aria-selected'] : 'false'}>
              {!hideCheckIcon && (
                <Checkbox icon={checkIcon} checkedIcon={checkedIcon} checked={selected} />
              )}

              {getOptionLabel(option) || '-'}
            </li>
          )}
          PaperComponent={PaperComponent}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder ?? (multiple ? 'Add' : 'Search')}
              label={animatedLabel ? label : null}
              InputProps={{
                ...params.InputProps,
                sx: {
                  ...textStyles,
                  borderRadius: 3,
                  backgroundColor: '#F8F8F8',

                  '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                  },

                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 1,
                    borderColor: '#307FF6',
                  },

                  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 1,
                    borderColor: '#F00',
                  },

                  '&.Mui-error + .MuiFormHelperText-root': {
                    fontFamily: 'ploni, sans-serif',
                    color: '#F00',
                    margin: '4px 0 0',
                    fontSize: '12px',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'ploni, sans-serif',
                  color: '#A4A4A4',

                  '&.Mui-focused': {
                    color: '#307FF6',
                  },
                },
              }}
              {...externalInputProps}
            />
          )}
          ListboxProps={{
            sx: {
              padding: 0,
              border: '1px solid #EEEFF2',
              borderRadius: 3,
              maxHeight: 250, // 50 x 5

              '.MuiAutocomplete-option': {
                ...textStyles,
                boxShadow: '0px 1px 0px 0px #F7F7F7',
                py: '4px',
                minHeight: { sm: 40 },

                '&.Mui-focused': {
                  backgroundColor: '#F8F8F8',
                },
              },
            },
          }}
          componentsProps={{
            popper: {
              modifiers: [{ name: 'offset', options: { offset: popperOffset ?? [0, 8] } }],
            },
            paper: {
              elevation: 0,
              sx: {
                // border: hideNoOptionsOnInit ? 'none' : '1px solid #EEEFF2',
                // borderRadius: 3,

                '.MuiAutocomplete-noOptions, .MuiAutocomplete-loading': {
                  fontFamily: 'ploni, sans-serif',
                  border: '1px solid #EEEFF2',
                  borderRadius: 3,
                  textAlign: 'left',
                },

                '.MuiAutocomplete-noOptions': {
                  display: hideNoOptionsOnInit ? 'none' : 'block',
                },
              },
            },
            popupIndicator: {
              sx: { padding: '4px' },
            },
          }}
          ChipProps={{
            deleteIcon: <CloseRoundedIcon />,

            sx: {
              backgroundColor: '#307FF6',
              fontFamily: 'ploni, sans-serif',
              color: '#fff',
              fontSize: '14px',
              height: '28px',
              px: '2px',

              '.MuiChip-deleteIcon': {
                color: '#fff',
                borderRadius: '50%',
                transition: 'all 100ms ease-in-out',

                ':hover': { backgroundColor: '#fff', color: '#307FF6' },
              },
            },
          }}
          disableListWrap={true}
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          loading={loading}
          onInputChange={(event, value, reason) => {
            setInputValue(value);
            onInputChange?.(event, value, reason);
          }}
          {...rest}
        />
      </div>
    </RTL>
  );
}

// ---

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    top: style.top,
  };

  return <li key={dataSet.key} {...dataSet.props} style={inlineStyle} />;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});
