import React, { ReactNode } from 'react';
import { Dialog } from '@blueprintjs/core';
import { XIcon } from '../svg/index';

import './modalDialog.scss';
export interface ModalDialogProps {
	/**[required][default=false] is the modal open or closed */
	isOpen: boolean;

	/**[optional][default=true] show close modal button inside header. will be ignored if showHeader=false*/
	showCloseButton?: boolean;

	/**[optional][default=true] show header */
	showHeader?: boolean;

	/**[optional] show a header title (text) */
	title?: string;

	/**[optional] icon to be displayed left to the title text. will be ignored if showHeader=false or no title*/
	titleIcon?: any;

	/**[optional] add your own classname */
	className?: string;

	/**[required] function to be executed when the modal is being closed */
	onClose: () => void;

	/**[optional][default=true] click outside the dialog to close it */
	clickOutsideToClose?: boolean;

	/**[optional] modal content */
	children?: ReactNode;
}

export function ModalDialog({
	isOpen = false,
	showCloseButton = true,
	className = '',
	onClose,
	children,
	showHeader = true,
	title = '',
	titleIcon,
	clickOutsideToClose = true,
	...props
}: ModalDialogProps) {
	return (
		<Dialog
			autoFocus={true}
			isOpen={isOpen}
			usePortal={true}
			canEscapeKeyClose={true}
			canOutsideClickClose={clickOutsideToClose}
			className={`modal-dialog-box ${className}`}
			icon={false}
			onClose={onClose}
			portalClassName={'modal-dialog-portal'}
			{...props}
		>
			{showHeader && (
				<header className='modal-box-header'>
					{title && (
						<h2 className='modal-title'>
							{titleIcon && <span className='title-icon'>{titleIcon}</span>}
							{title}
						</h2>
					)}
					{showCloseButton && (
						<button className={'top-close-modal-btn'} onClick={onClose}>
							<XIcon />
						</button>
					)}
				</header>
			)}
			<div className={'modal-content'}>{children ? children : null}</div>
		</Dialog>
	);
}
