import React, { LegacyRef, TextareaHTMLAttributes } from 'react';

import { generateHtmlForId } from '../common/utils/utilFunctions';
import { FormControlProps } from '../common/utils/formUtils';
import { Label } from '../label/Label';
import { IconButton } from '@mui/material';
import ClearRounded from '@mui/icons-material/ClearRounded';

import './textArea.scss';
import '../common/formControl.scss';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, FormControlProps {
	/**[optional] textarea's value*/
	value?: string;

	/**[optional] onChange event*/
	onChange?: (event: any) => void;

	/**[optional][default=4] number of rows*/
	rows?: number;

	/**[optional][default=false] allow resize textarea*/
	allowResize?: boolean;

	/** Pass a ref to the input element */
	inputRef?: LegacyRef<HTMLTextAreaElement>;

	onClear?: () => void;
}

export function TextArea({
	id,
	required = false,
	disabled = false,
	value,
	onChange,
	rows = 2,
	allowResize = false,
	label,
	className = '',
	placeholder = '',
	labelIcon,
	inline = false,
	hiddenLabel = false,
	error,
	ariaRequired = false,
	inputRef,
	onClear,
	...props
}: TextAreaProps) {
	const generatedId = id ? id : generateHtmlForId(label);
	const errorMessageId = `error-message-for-${generatedId}`;

	return (
		<div
			className={`reusable-textarea-wrapper common-form-control ${
				disabled ? 'disabled-textarea' : ''
			} ${error ? 'has-error' : ''} ${className} ${inline ? 'inline-element' : ''}`}
		>
			<Label
				text={label}
				htmlFor={generatedId}
				icon={labelIcon}
				inline={inline}
				disabled={disabled}
				hidden={hiddenLabel}
			/>
			<div className='reusable-textarea-container control-body'>
				<textarea
					className={'reusable-textarea'}
					id={generatedId}
					rows={rows}
					style={allowResize ? undefined : { resize: 'none' }}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					disabled={disabled}
					required={required}
					aria-required={ariaRequired}
					aria-describedby={error && errorMessageId}
					aria-invalid={!!error}
					ref={inputRef}
					{...props}
				/>

				{onClear && <IconButton sx={{ p: '4px', position: 'absolute', top: 6, right: 6 }} onClick={onClear}>
            		<ClearRounded fontSize="small" />
          		</IconButton>}
			</div>
			{error && (
				<p id={errorMessageId} className={'control-error-message'}>
					{error}
				</p>
			)}
		</div>
	);
}
