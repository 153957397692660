import React, { ReactNode, useEffect, useState } from 'react';
import { MultiSelect as bpMultiSelect, IItemRendererProps } from '@blueprintjs/select';

import { FormControlProps } from '../..';
import { SelectItem, SelectMenuProps } from '../selectUtils';
import { SelectDropdownItem } from '../selectDropdownItem/SelectDropdownItem';
import { generateHtmlForId } from '../../common/utils/utilFunctions';
import { Label } from '../../label/Label';
import { XIcon } from '../../svg/index';

import '../../listedTag/listedTag.scss';
import './multiSelect.scss';

export interface MultiSelectProps extends FormControlProps, SelectMenuProps {
	/**[optional] list of items to select from */
	selectedItems?: SelectItem[];

	/**[required] function to be exectuted when a tag is being removed */
	onRemove: (item: SelectItem) => void;

	/**[optional][default=false] open on key down */
	openOnKeyDown?: boolean;

	/**[optional][default=false] allow clearing the selected tags, will require a onClearAllTags function*/
	allowClearAllTags?: boolean;

	/**[optional] will be executed on clear all tags button clicked */
	onClearAllTags?: () => void;

	/**[optional][default=false] if no results, allow adding the new query as an item, if true - createNewItemFromQuery will be required*/
	allowCreateNewItem?: boolean;

	/**[optional] will be executed onClick on the add button when no result found for a querry. will be ignored if allowCreateNewItem=false */
	createNewItemFromQuery?: (query: string) => SelectItem;

	/**[optional] the function will be executed on click selected item */
	onTagClick?: (item: SelectItem) => void;

	isSearching?: boolean;
}

export const MultiSelectOfType = bpMultiSelect.ofType<SelectItem>();

export function MultiSelect({
	items = [],
	selectedItems = [],
	placeholder = '',
	label = '',
	hiddenLabel = false,
	id,
	onItemSelect,
	onRemove,
	className,
	query,
	openOnKeyDown = false,
	allowClearAllTags = false,
	onClearAllTags,
	allowCreateNewItem = false,
	createNewItemFromQuery,
	fillContainerWidth = true,
	onQueryTextChange,
	labelIcon,
	inline,
	disabled,
	showMenuImages = false,
	menuItemImgRenderer,
	error,
	onTagClick,
	isSearching = false
}: MultiSelectProps) {
	const [lastSearchText, setLastSearchText] = useState<string>('');
	const [currentQuery, setCurrentQuery] = useState<string>('');
	const [itemsArray, setItemsArray] = useState<SelectItem[]>(getItemsArray());

	const generatedId: string = id ? id : generateHtmlForId(label);

	useEffect(() => {
		const filtereditemsAtrray = getItemsArray();
		setItemsArray(filtereditemsAtrray);
	}, [items, selectedItems]);

	function getItemsArray() {
		if (!items?.length) {
			return [];
		}

		const filtereditemsAtrray = items.filter(item => {
			return !selectedItems?.find(selectedItem => selectedItem.elId == item.elId);
		});

		return filtereditemsAtrray;
	}

	function renderSelectItem(item: SelectItem, itemProps: IItemRendererProps) {
		if (selectedItems?.includes(item)) {
			return null;
		}

		return (
			<SelectDropdownItem
				key={`menuItem_multiselect_${item?.elId}_${generatedId}`}
				item={item}
				itemProps={itemProps}
				styleType={'default'}
				activeClassName={'active-select-dropdown-item'}
				query={itemProps.query}
				withHighlightedQueryText={true}
				showMenuImages={showMenuImages}
				imageCustomRenderer={menuItemImgRenderer}
				shouldDismissPopover={false}
			/>
		);
	}

	const itemPredicate = (query: any, item: any) => {
		return item?.value?.toLowerCase().indexOf(query?.toLowerCase()) >= 0;
	};

	const onQueryChange = (text: string) => {
		setCurrentQuery(text);
		if (query && lastSearchText !== text) {
			setLastSearchText(text);
			query(text);

			onQueryTextChange && onQueryTextChange(text);
		}
	};

	function renderTags(item: SelectItem) {
		const onTagItemClick = (e: React.MouseEvent, item: SelectItem) => {
			e.stopPropagation() 
			onTagClick && onTagClick(item);
		}

		return item ? <span className='tag-item' onClick={(e) => onTagItemClick(e, item)}>{item.value}</span> : null;
	}

	const renderNoResults: ReactNode = (
		<SelectDropdownItem
			key={`menuItem_select_noresult_${generatedId}`}
			query={currentQuery}
			noResultItem={true}
			createNewItemFromQuery={(value) => {
				if(createNewItemFromQuery){
					createNewItemFromQuery(value)
					setCurrentQuery("")
				}
			} }
			allowCreateNewItem={allowCreateNewItem}
		/>
	);

	const clearAllTagsButton =
		allowClearAllTags && onClearAllTags && (selectedItems?.length || currentQuery) ? (
			<a
				role='button'
				className='reset-button'
				onClick={() => {
					setCurrentQuery('');
					onClearAllTags();
				}}
			>
				<XIcon />
			</a>
		) : (
			undefined
		);

	return (
		<div
			className={`multiselect-wrappr ${className} ${inline ? 'inline-element' : ''} ${
				error ? 'has-error' : ''
			} `}
		>
			<Label
				text={label}
				hidden={hiddenLabel}
				icon={labelIcon}
				htmlFor={generatedId}
				inline={inline}
				disabled={disabled}
			/>
			<MultiSelectOfType
				placeholder={placeholder}
				popoverProps={{
					usePortal: false,
					modifiers: { arrow: { enabled: false } },
					hasBackdrop: false,
					className: !currentQuery && !items.length ? 'close' : undefined,
				}}
				items={itemsArray}
				onItemSelect={onItemSelect}
				itemRenderer={renderSelectItem}
				tagRenderer={renderTags}
				selectedItems={selectedItems}
				tagInputProps={{
					rightElement: clearAllTagsButton,
					inputProps: {
						id: generatedId,
						onKeyDown: e => e.key === 'Enter' && e.preventDefault()
					},
					tagProps: (_value, index) => {
						const item = selectedItems[index];
						let className = 'reusable-listed-tag';

						if (item.type === 1) className += ' mandatory';
						if (item.type === 2) className += ' excluded';
			
						return {
						  minimal: true,
						  round: true,
						  className,
						  interactive: Boolean(onTagClick),
						};
					  },
				}}
				fill={fillContainerWidth}
				itemPredicate={itemPredicate}
				openOnKeyDown={openOnKeyDown}
				resetOnQuery={true}
				resetOnSelect={true}
				onQueryChange={onQueryChange}
				onRemove={onRemove}
				noResults={renderNoResults}
				query={currentQuery}
				itemListRenderer={
					isSearching
					  ? () => <span className="select-dropdown-searching">Searching...</span>
					  : undefined
				}
			/>
			{error && <p className={'control-error-message'}>{error}</p>}
		</div>
	);
}
