import { Icon } from '@blueprintjs/core';
import { IconButton } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import React, { MouseEvent, ReactNode, useState } from 'react';

export interface MenuItem {
  text: string;
  icon?: ReactNode;
  onClick?: () => void;
}

export interface MenuProps extends Omit<MuiMenuProps, 'open'> {
  children: ReactNode;
  items?: MenuItem[];
  showAsIcon?: boolean;
  endIcon?: ReactNode;
  minWidth?: number | string;
  maxHeight?: number | string;
  buttonProps?: ButtonProps;
  minWidthButton?: number | string;
}

export function Menu({
  children,
  items = [],
  showAsIcon = false,
  disableScrollLock = true,
  minWidth = 160,
  maxHeight,
  buttonProps,
  minWidthButton,
  endIcon = <Icon icon="chevron-down" />,
  ...props
}: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="bs-menu">
      {!showAsIcon && (
        <StyledButton
          disableElevation
          variant="contained"
          endIcon={items.length ? endIcon : null}
          onClick={handleClick}
          className="bs-menu-button"
          sx={{
            justifyContent: 'space-between',
            minWidth: minWidthButton,
          }}
          {...buttonProps}
        >
          {children}
        </StyledButton>
      )}

      {showAsIcon && (
        <IconButton onClick={handleClick} className="bs-menu-button" {...buttonProps}>
          {children}
        </IconButton>
      )}

      {items.length > 0 && (
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={disableScrollLock}
          sx={{
            '.MuiPaper-root': { minWidth, maxHeight },
          }}
          {...props}
        >
          {items.map((item, index) => (
            <MuiMenuItem
              key={index}
              onClick={() => {
                setAnchorEl(null);
                item.onClick && item.onClick();
              }}
            >
              {item.icon}
              {item.text}
            </MuiMenuItem>
          ))}
        </StyledMenu>
      )}
    </div>
  );
}

const StyledMenu = styled((props: MuiMenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))({
  '.MuiPaper-root': {
    borderRadius: 12,
    border: '1px solid #EEEFF2',
    marginTop: 8,
    // minWidth: 160,
    color: '#6C6C6C',
    boxShadow: '0px 12px 40px 0px rgba(0, 0, 0, 0.09)',

    '.MuiMenu-list': {
      padding: 0,
    },

    '.MuiMenuItem-root': {
      padding: '12px 24px',
      boxShadow: '0px 1px 0px 0px #F7F7F7',

      fontSize: '16px',
      fontWeight: 400,
      color: '#6C6C6C',
      lineHeight: 1,
      fontFamily: 'ploni, sans-serif',

      '&:hover': {
        color: '#404040',
        backgroundColor: '#F8F8F8',
      },
    },
  },
});

const StyledButton = styled(Button)({
  padding: '12px 16px',
  borderRadius: '12px',
  backgroundColor: '#F8F8F8',

  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 400,
  color: '#6C6C6C',
  lineHeight: 1,
  fontFamily: 'ploni, sans-serif',

  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#307FF6',
  },
});
