import React, { useState } from 'react';

import { ModalDialog } from '../modalDialog/ModalDialog';
import { Button } from '../button/Button';
import { Input } from '../input/Input';
import { LinksObject, LinkTypes } from './linksUtils';

import { FacebookFIcon, LinkedinINIcon, TwitterIconNoBg, GithubIcon, GlobeIcon } from '../svg/index';
import formValidations from '../common/utils/formValidations';

interface EditSocialLinksModalProps {
	socialLinks: LinksObject;
	isOpen: boolean;
	onClose: () => void;
	onSave: (socialLinks: LinksObject) => void;
}

export function EditSocialLinksModal({
	isOpen = false,
	onClose,
	onSave,
	socialLinks
}: EditSocialLinksModalProps) {
	const [linkedin, setLinkedin] = useState(socialLinks?.linkedin);
	const [linkedinError, setLinkedinError] = useState('');

	const [facebook, setFacebook] = useState(socialLinks?.facebook);
	const [facebookError, setFacebookError] = useState('');

	const [twitter, setTwitter] = useState(socialLinks?.twitter);
	const [twitterError, setTwitterError] = useState('');

	const [github, setGithub] = useState(socialLinks?.github);
	const [githubError, setGithubError] = useState('');

	const [website, setWebsite] = useState(socialLinks?.website);
	const [websiteError, setWebsiteError] = useState('');

	function handleSubmit(event: any) {
		event.preventDefault();

		if (validateForm()) {
			let updatedSocialLinks: LinksObject = {};

			if (linkedin) {
				updatedSocialLinks[LinkTypes.LINK_TYPE_LINKEDIN] = linkedin;
			}
			if (facebook) {
				updatedSocialLinks[LinkTypes.LINK_TYPE_FACEBOOK] = facebook;
			}
			if (twitter) {
				updatedSocialLinks[LinkTypes.LINK_TYPE_TWITTER] = twitter;
			}
			if (github) {
				updatedSocialLinks[LinkTypes.LINK_TYPE_GITHUB] = github;
			}
			if (website) {
				updatedSocialLinks[LinkTypes.LINK_TYPE_WEBSITE] = website;
			}

			onSave(updatedSocialLinks);
		}
	}

	function validateForm() {
		let isValid = true;
		//linkedin
		if (linkedin) {
			if (!formValidations.max(linkedin, 1000)) {
				isValid = false;
				setLinkedinError('Max  1000 characters');
			}

			if (!formValidations.isLinkedinURLValid(linkedin)) {
				isValid = false;
				setLinkedinError('Invalid link');
			}
		}

		//facebook
		if (facebook) {
			if (!formValidations.max(facebook, 1000)) {
				isValid = false;
				setFacebookError('Max  1000 characters');
			}

			if (!formValidations.isLink(facebook)) {
				isValid = false;
				setFacebookError('Invalid link');
			}
		}

		//twitter
		if (twitter) {
			if (!formValidations.max(twitter, 1000)) {
				isValid = false;
				setTwitterError('Max  1000 characters');
			}

			if (!formValidations.isLink(twitter)) {
				isValid = false;
				setTwitterError('Invalid link');
			}
		}

		//github
		if (github) {
			if (!formValidations.max(github, 1000)) {
				isValid = false;
				setGithubError('Max  1000 characters');
			}

			if (!formValidations.isLink(github)) {
				isValid = false;
				setGithubError('Invalid link');
			}
		}

		//website
		if (website) {
			if (!formValidations.max(website, 1000)) {
				isValid = false;
				setWebsiteError('Max  1000 characters');
			}

			if (!formValidations.isLink(website)) {
				isValid = false;
				setWebsiteError('Invalid');
			}
		}

		return isValid;
	}

	return (
		<ModalDialog
			className='editSocialNetworks'
			onClose={() => onClose()}
			isOpen={isOpen}
			title={'Edit Social Links'}
		>
			<form onSubmit={handleSubmit} className={'modal-form'}>
				<div className='row'>
					<Input
						value={linkedin}
						placeholder={'Linkedin Link'}
						onChange={e => {
							setLinkedin(e.target.value);
							setLinkedinError('');
						}}
						label={'Linkedin link'}
						hiddenLabel={true}
						inputLeftIcon={<LinkedinINIcon />}
						allowReset={true}
						onReset={() => {
							setLinkedin('');
							setLinkedinError('');
						}}
						error={linkedinError}
					/>
				</div>
				<div className='row'>
					<Input
						value={facebook}
						placeholder={'Facebook Link'}
						onChange={e => {
							setFacebook(e.target.value);
							setFacebookError('');
						}}
						label={'Facebook'}
						hiddenLabel={true}
						inputLeftIcon={<FacebookFIcon />}
						allowReset={true}
						onReset={() => {
							setFacebook('');
							setFacebookError('');
						}}
						error={facebookError}
					/>
				</div>
				<div className='row'>
					<Input
						value={twitter}
						placeholder={'Twitter Link'}
						onChange={e => {
							setTwitter(e.target.value);
							setTwitterError('');
						}}
						label={'Twitter'}
						hiddenLabel={true}
						inputLeftIcon={<TwitterIconNoBg />}
						allowReset={true}
						onReset={() => {
							setTwitter('');
							setTwitterError('');
						}}
						error={twitterError}
					/>
				</div>
				<div className='row'>
					<Input
						value={github}
						placeholder={'Github Link'}
						onChange={e => {
							setGithub(e.target.value);
							setGithubError('');
						}}
						label={'Github'}
						hiddenLabel={true}
						inputLeftIcon={<GithubIcon />}
						allowReset={true}
						onReset={() => {
							setGithub('');
							setGithubError('');
						}}
						error={githubError}
					/>
				</div>
				<div className='row'>
					<Input
						value={website}
						placeholder={'Website Link'}
						onChange={e => {
							setWebsite(e.target.value);
							setWebsiteError('');
						}}
						label={'Personal Website'}
						hiddenLabel={true}
						inputLeftIcon={<GlobeIcon />}
						allowReset={true}
						onReset={() => {
							setWebsite('');
							setWebsiteError('');
						}}
						error={websiteError}
					/>
				</div>
				<div className={'modal-form-actions'}>
					<Button variant='primary' type='submit'>
						Save
					</Button>
				</div>
			</form>
		</ModalDialog>
	);
}
