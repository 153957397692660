import React from 'react';
import { SelectItem } from '../dropdowns/selectUtils';
import { ListedTag } from '../listedTag/ListedTag';

import './tagsCollection.scss';

export interface TagsCollectionProps {
	/**[required][default: []] items {elId: number, value: string}[] */
	items: SelectItem[];

	/**[optional][default=true] */
	removable?: boolean;

	/**[optional] function to be executed on tag being removed. */
	onRemoveItem?: (item: SelectItem) => void;

	/**[optional][default: ''] custom classname */
	className?: string;
}

export function TagsCollection({
	items = [],
	onRemoveItem,
	className = '',
	removable = true
}: TagsCollectionProps) {
	return (
		<div className={`tags-collection ${className}`}>
			{items?.length ? (
				<ul className='tags-collection-list'>
					{items.map((item, index) => (
						<li
							className='tags-collection-list-item'
							key={`tags-collection-list-item-${index}-${item.elId}`}
						>
							<ListedTag
								removable={removable}
								onRemove={() => (onRemoveItem && removable ? onRemoveItem(item) : null)}
							>
								{item.value}
							</ListedTag>
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
}
