export interface LinksObject {
	facebook?: string;
	linkedin?: string;
	github?: string;
	twitter?: string;
	website?: string;
}

export enum LinkTypes {
	LINK_TYPE_FACEBOOK = 'facebook',
	LINK_TYPE_LINKEDIN = 'linkedin',
	LINK_TYPE_GITHUB = 'github',
	LINK_TYPE_TWITTER = 'twitter',
	LINK_TYPE_STACKOVERFLOW = 'stackoverflow',
	LINK_TYPE_WEBSITE = 'website'
}
