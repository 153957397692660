import React, { LegacyRef } from 'react';

import { Select } from '../../dropdowns/select/Select';
import { Input } from '../Input';
import { Label } from '../../label/Label';

import { countryItems } from './countries';
import { FormControlProps } from '../../common/utils/formUtils';
import { SelectItem } from '../../dropdowns/selectUtils';
import { generateHtmlForId } from '../../common/utils/utilFunctions';

import './phoneInput.scss';
import '../../common/formControl.scss';

export interface PhoneInputProps extends FormControlProps {
	/**[required] phone number as string */
	phoneNumber: string;
	/**[required] on phone number changed */
	onPhoneNumberChange: (value: string) => void;
	/**[optional] phone number input placeholder */
	phoneNumberPlaceHolder?: string;

	/**[required] iso2 (2 chars string)*/
	countryCode: string;

	/**[required] function to be execute on country select changed */
	onCountryCodeChange: (value: string) => void;

	/**[optional] placeholder for country code select element */
	countryCodePlaceholder?: string;

	/**[optional][default=true] inline style for the inner element */
	inlineInner?: boolean;

	inputName?: string

	/** Pass a ref to the input element */
	inputRef?: LegacyRef<HTMLInputElement>
}

export function PhoneInput({
	phoneNumber,
	onPhoneNumberChange,
	phoneNumberPlaceHolder = 'Enter phone number',
	countryCode,
	onCountryCodeChange,
	countryCodePlaceholder = 'select..',
	inlineInner = true,
	label = 'Phone Number',
	hiddenLabel = false,
	labelIcon,
	inline = false,
	disabled = false,
	id,
	className = '',
	error,
	inputRef,
	inputName
}: PhoneInputProps) {
	const generatedId = id ? id : generateHtmlForId(label);

	function flagImgRenderer(item: SelectItem) {
		return <img className='flag-img' src={item?.img} alt={`${item?.value}`} />;
	}

	return (
		<div
			className={`phone-input common-form-control ${inline ? 'inline-element' : ''} ${
				error ? 'has-error' : ''
			} ${className}`}
		>
			<Label
				text={label}
				hidden={hiddenLabel}
				icon={labelIcon}
				htmlFor={generatedId}
				inline={inline}
				disabled={disabled}
			/>
			<div className={`phone-input-inner ${inlineInner ? 'inline-inner' : ''}`}>
				<Select
					id={generatedId}
					items={countryItems}
					selectedItem={
						countryCode
							? countryItems.find((country) => country.elId === countryCode)
							: undefined
					}
					showMenuImages={true}
					filterable={true}
					onItemSelect={(item) => item.dialCode && onCountryCodeChange(`${item.elId}`)}
					placeholder={countryCodePlaceholder}
					menuItemImgRenderer={flagImgRenderer}
					label={'Country Code:'}
					hiddenLabel={true}
					toggleButtonContentRenderer={() => {
						const currentCountry = countryItems.find(
							(country) => country.elId === countryCode
						);
						return (
							<>
								<img src={currentCountry?.img} alt={`${currentCountry?.elId}`} />
								<span>{currentCountry?.value}</span>
							</>
						);
					}}
				/>
				<Input
					name={inputName}
					inputRef={inputRef}
					value={phoneNumber}
					onChange={(event) => { const cleanValue = event.target.value.replace(/[^0-9\-]/g, ''); onPhoneNumberChange(cleanValue) }}
					placeholder={phoneNumberPlaceHolder}
					label={'Phone number:'}
					hiddenLabel={true}
					allowReset={true}
					onReset={() => onPhoneNumberChange('')}
					type='text'
					min='0'
				/>
			</div>
			{error && <p className={'control-error-message'}>{error}</p>}
		</div>
	);
}
