import React from 'react';
import { useIsMobile } from '../common/utils/hooks/useIsMobile';

import './loader.scss';

export interface LoaderProps {}

export function Loader({}: LoaderProps) {
	const isMobile = useIsMobile();

	return <div className={isMobile ? 'lds-dual-ring mobile' : 'lds-dual-ring'} />;
}
