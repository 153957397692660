import React, { InputHTMLAttributes, LegacyRef, ReactNode } from 'react';

import { generateHtmlForId } from '../common/utils/utilFunctions';
import { FormControlProps } from '../common/utils/formUtils';
import { Label } from '../label/Label';
import { XIcon } from '../svg/index';

import './input.scss';
import '../common/formControl.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, FormControlProps {
	/**[optional] input's value*/
	value?: string;

	/**[optional] icon to be rendered left to the input element.*/
	inputLeftIcon?: ReactNode;

	/**[optional] on change function*/
	onChange?: (event?: any) => void;

	/**[optional][default='text'] supported input types: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url' */
	type?: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url' | 'phone';

	/**[optional][default='off'] allow browser autocomplete,*/
	autoComplete?: 'off' | 'on';

	/**[optional] Placeholder text*/
	placeholder?: string;

	/**[optional][default=true] */
	allowReset?: boolean;

	/**[optional] on reset button clicked */
	onReset?: () => void;

	/**[optional] show red error under the input, good for validation errors */
	error?: string;

	/**[optional] for type number: min value */
	min?: string;

	/**[optional] for type number: max value */
	max?: string;

	/**[optional] text to be added right/left inside the input */
	blockedPlaceholder?: string;

	/**[optional][default='left'] side of the blocked placeholder text */
	blockedPlaceholderSide?: 'left' | 'right';

	/** Pass a ref to the input element */
	inputRef?: LegacyRef<HTMLInputElement>;
}

export function Input({
	required = false,
	label = '',
	id,
	className = '',
	disabled = false,
	onChange,
	labelIcon,
	inputLeftIcon,
	type = 'text',
	hiddenLabel = false,
	placeholder = '',
	autoComplete = 'off',
	inline = false,
	value = '',
	onReset,
	allowReset = true,
	error = '',
	min,
	max,
	ariaRequired = false,
	blockedPlaceholder,
	blockedPlaceholderSide = 'left',
	inputRef,
	...props
}: InputProps) {
	const generatedId = id ? id : generateHtmlForId(label);
	const errorMessageId = `error-message-for-${generatedId}`;

	return (
		<div
			className={`common-form-control reusable-input-wrapper ${inline ? 'inline-element' : ''} ${
				disabled ? 'disabled-input-wrapper' : ''
			} reusable-input-type-${type} ${error ? 'has-error' : ''}  ${className}`}
		>
			<Label
				text={label}
				htmlFor={generatedId}
				icon={labelIcon}
				inline={inline}
				disabled={disabled}
				hidden={hiddenLabel}
			/>
			<div
				className={`control-body reusable-input-container ${
					inputLeftIcon ? 'reusable-input-has-left-icon' : ''
				} ${allowReset ? 'reset-allowed' : ''}`}
			>
				{inputLeftIcon && <span className='input-left-icon'>{inputLeftIcon}</span>}
				{blockedPlaceholder && blockedPlaceholderSide === 'left' ? (
					<span className={`blocked-placeholder side-${blockedPlaceholder}`}>
						{blockedPlaceholder}
					</span>
				) : null}
				<input
					required={required}
					id={generatedId}
					className='reusable-input'
					disabled={disabled}
					onChange={onChange}
					type={type}
					placeholder={placeholder}
					autoComplete={autoComplete}
					value={value}
					min={min}
					max={max}
					aria-required={ariaRequired}
					aria-describedby={error && errorMessageId}
					aria-invalid={!!error}
					ref={inputRef}
					{...props}
				/>
				{blockedPlaceholder && blockedPlaceholderSide === 'right' ? (
					<span className={`blocked-placeholder side-${blockedPlaceholder}`}>
						{blockedPlaceholder}
					</span>
				) : null}
				{allowReset && onReset && value ? (
					<a
						role='button'
						key={`resetButton-${generatedId}`}
						className={'reset-button'}
						onClick={onReset}
					>
						<XIcon />
					</a>
				) : null}
			</div>
			{error && (
				<p id={errorMessageId} className={'control-error-message'}>
					{error}
				</p>
			)}
		</div>
	);
}
