import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import React from 'react';
import classNames from "classnames";

function InputLabel({ children, className, ...rest }: FormLabelProps) {
  if (!children) return null;

  return (
    <FormLabel
      className={classNames("input-label", className)}
      sx={{
        mb: 2,
        display: 'block',
        fontSize: '1rem',
        fontWeight: 400,
        color: '#404040',
        lineHeight: '130%',
        letterSpacing: 'normal',
        fontFamily: 'ploni, sans-serif',
        textAlign: 'inherit',
      }}
      {...rest}
    >
      {children}
    </FormLabel>
  );
}

export default InputLabel;
