import { Button } from '@blueprintjs/core';
import cn from 'classnames';
import React, { ReactNode, useState } from 'react';
import { PencilIcon, TrashIcon } from '../svg';

import './editableField.scss';

export interface EditableFieldProps {
  label?: string;
  onSave: () => void;
  onCancel: () => void;
  renderContent: (isEdit: boolean) => ReactNode;

  className?: string;
  isHorizontal?: boolean;
  initialEdit?: boolean;
  hasLabel?: boolean;
  onRemove?: () => void;
}

export function EditableField(props: EditableFieldProps) {
  const {
    label,
    onSave,
    onCancel,
    onRemove,
    renderContent,
    className = '',
    isHorizontal = false,
    initialEdit = false,
    hasLabel = false,
  } = props;

  const [isEdit, setIsEdit] = useState(initialEdit);

  const handleCancelClick = () => {
    onCancel();
    setIsEdit(false);
  };

  const handleSaveClick = () => {
    onSave();
    setIsEdit(false);
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  return (
    <div
      className={cn('editable-field', {
        'is-edit': isEdit,
        'has-label': !!label || hasLabel,
        'is-horizontal': isHorizontal,
        [className]: !!className,
      })}
    >
      {!!label && <label className="editable-label">{label}</label>}

      {renderContent(isEdit)}

      {!isEdit && (
        <div className="editable-right">
          {onRemove && <Button minimal icon={<TrashIcon />} onClick={onRemove} />}
          <Button minimal icon={<PencilIcon />} onClick={handleEditClick} />
        </div>
      )}

      {isEdit && (
        <div className="editable-actions">
          <Button className="editable-cancel" minimal onClick={handleCancelClick}>
            Cancel
          </Button>

          <Button className="editable-save" minimal onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
