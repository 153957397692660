import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import React from 'react';

export interface AvatarProps extends MuiAvatarProps {
  bgcolor?: string;
  size?: number;
  name?: string;
}

export function Avatar({
  alt,
  src,
  children,
  bgcolor,
  size = 40,
  name = '',
  sx: externalStyles,
  ...props
}: AvatarProps) {
  return (
    <MuiAvatar
      alt={alt}
      src={src}
      sx={{
        bgcolor,
        width: size,
        height: size,
        fontFamily: 'ploni, sans-serif',
        color: '#fff',
        ...externalStyles,
      }}
      {...props}
    >
      {name ? getAvatarName(name) : children}
    </MuiAvatar>
  );
}

const getAvatarName = (displayName?: string) => {
  if (!displayName) return '';

  const names = displayName.split(' ');

  if (names.length === 1) return `${names[0].charAt(0)}${names[0].charAt(1)}`;
  return `${names[0].charAt(0)}${names[1].charAt(0)}`;
};
