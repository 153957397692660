import { useState, useEffect } from 'react';

export function useDebouncedState<T>(
	initialState: T,
	debounceTime: number
): [T, React.Dispatch<React.SetStateAction<T>>, T] {
	const [state, setState] = useState<T>(initialState);
	const [debouncedState, setDebouncedState] = useState<T>(initialState);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedState(state);
		}, debounceTime);

		return () => {
			clearTimeout(timer);
		};
	}, [state, debounceTime]);

	return [state, setState, debouncedState];
}
