import { EditableText, EditableTextProps } from '@blueprintjs/core';
import ClearRounded from '@mui/icons-material/ClearRounded';
import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { TextArea } from '../textArea';
import { EditableField } from './EditableField';

export interface EditableTextFieldProps {
  label?: string;
  initialText?: string;
  multiline?: boolean;
  onTextSave: (text: string) => void;
  inputProps?: EditableTextProps;
  isHorizontal?: boolean;
  parseHtml?: boolean;
  allowClear?: boolean;
}

export function EditableTextField(props: EditableTextFieldProps) {
  const {
    label = '',
    initialText = '',
    multiline = false,
    onTextSave,
    inputProps,
    isHorizontal = false,
    parseHtml = false,
    allowClear = true,
  } = props;
  const [text, setText] = useState(initialText);

  const renderContent = (isEditMode: boolean) => {
    if (parseHtml && !isEditMode) {
      const className = multiline ? 'editable-value multiline' : 'editable-value';
      const html = formatToInnerHtml(initialText || 'None');

      return <p className={className} dangerouslySetInnerHTML={{ __html: html }} />;
    }

    if (multiline) {
      if (!isEditMode) return <p className="editable-value multiline">{initialText || 'None'}</p>;
      return (
        <TextArea
          rows={6}
          value={text}
          onChange={e => setText(e.target.value)}
          onClear={allowClear ? () => setText('') : undefined}
        />
      );
    }

    return (
      <Box position="relative" className="editable-value-wrapper">
        <EditableText
          className="editable-value"
          value={text}
          onChange={setText}
          disabled={!isEditMode}
          isEditing={isEditMode}
          placeholder={isEditMode || Boolean(initialText) ? '' : 'None'}
          {...inputProps}
        />

        {isEditMode && allowClear && (
          <IconButton
            sx={{
              p: '4px',
              position: 'absolute',
              top: '50%',
              right: 4,
              transform: 'translateY(-50%)',
            }}
            onClick={() => setText('')}
          >
            <ClearRounded fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <EditableField
      label={label}
      onSave={() => onTextSave(text)}
      onCancel={() => setText(initialText)}
      renderContent={renderContent}
      isHorizontal={isHorizontal}
    />
  );
}

const formatToInnerHtml = (str: string) => {
  let array = ['?', '\\r', '\\t'];

  let newText = str;
  newText = newText && newText.split('\\n').join('<br>');
  for (let i = 0; i < array.length; i++) {
    newText = newText.split(array[i]).join(' ');
  }

  return newText;
};
