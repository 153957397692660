import { Box, OutlinedInput, Stack, Typography } from '@mui/material';
import MuiSlider, { SliderProps as MuiSliderProps } from '@mui/material/Slider';
import React, { useEffect, useState } from 'react';
import InputLabel from './InputLabel';

export interface SliderProps {
  label?: string;
  externalProps?: MuiSliderProps;

  value: number | number[];
  onChange: (value: number | number[]) => void;

  min?: number;
  max?: number;
}

const textStyles = {
  fontFamily: 'ploni, sans-serif',
  color: '#6C6C6C',
};

const textFieldStyles = {
  ...textStyles,
  maxWidth: '80px',

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#C7C7C7',
    ':focus': { borderColor: '#307ff6' },
  },
};

const sliderStyles = {
  height: '10px',

  '.MuiSlider-rail': {
    backgroundColor: '#C7C7C7',
  },

  '.MuiSlider-track': {
    backgroundColor: '#307FF6',
  },

  '.MuiSlider-thumb': {
    width: '24px',
    height: '24px',
    border: '4px solid #307FF6',
    backgroundColor: '#F8F8F8',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const Slider = ({ label, min, max, value, onChange, externalProps }: SliderProps) => {
  // const isRangeMode = Array.isArray(value);

  const [input, setInput] = useState(Array.isArray(value) ? '' : value);
  const [minInput, setMinInput] = useState(Array.isArray(value) ? value[0] : '');
  const [maxInput, setMaxInput] = useState(Array.isArray(value) ? value[1] : '');

  useEffect(() => {
    if (Array.isArray(value)) {
      setMinInput(value[0]);
      setMaxInput(value[1]);
    } else {
      setInput(value);
    }
  }, [value]);

  const handleSliderChange = (_event: Event, value: number | number[], _activeThumb: number) => {
    onChange(value);

    // if (Array.isArray(value)) {
    //   setMinInput(value[0]);
    //   setMaxInput(value[1]);
    // } else {
    //   setInput(value);
    // }
  };

  const validInput = (inputValue: string) => {
    if (min !== undefined && parseInt(inputValue) < min) return min;
    if (max !== undefined && parseInt(inputValue) > max) return max;

    return inputValue;
  };

  return (
    <div className="slider">
      <InputLabel>{label}</InputLabel>

      <Box
        sx={{
          padding: '16px 20px',
          backgroundColor: '#F8F8F8',
          borderRadius: 3,
          textAlign: 'right',
        }}
      >
        {Array.isArray(value) && (
          <Stack direction="row" justifyContent="space-between" mb={1} gap={3}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography component="span" sx={textStyles}>
                Min
              </Typography>

              <OutlinedInput
                sx={textFieldStyles}
                size="small"
                type="number"
                value={minInput}
                onChange={event => {
                  setMinInput(validInput(event.target.value));
                  onChange([Number(validInput(event.target.value)), value[1]]);
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" gap={1}>
              <Typography component="span" sx={textStyles}>
                Max
              </Typography>

              <OutlinedInput
                sx={textFieldStyles}
                type="number"
                size="small"
                value={maxInput}
                onChange={event => {
                  setMaxInput(validInput(event.target.value));
                  onChange([value[0], Number(validInput(event.target.value))]);
                }}
              />
            </Stack>
          </Stack>
        )}

        {!Array.isArray(value) && (
          <OutlinedInput
            sx={{ ...textFieldStyles, mb: 1 }}
            type="number"
            size="small"
            value={input}
            onChange={event => {
              setInput(validInput(event.target.value));
              onChange(Number(validInput(event.target.value)));
            }}
          />
        )}

        <MuiSlider
          sx={sliderStyles}
          disableSwap
          value={value}
          onChange={handleSliderChange}
          min={min}
          max={max}
          {...externalProps}
        />

        <Stack direction="row" justifyContent={'space-between'}>
          <Typography component="span" sx={textStyles}>
            {min ?? ''}
          </Typography>

          <Typography component="span" sx={textStyles}>
            {max ?? ''}
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};
