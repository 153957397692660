import React, { useState } from 'react';

import { LinkedinIcon, FacebookIcon, GithubIcon, TwitterIcon, GlobeIcon, EditIcon } from '../svg';
import { LinksObject, LinkTypes } from './linksUtils';
import { EditSocialLinksModal } from './EditSocialLinksModal';

import './socialLinks.scss';

export interface SocialLinksProps {
	/**[required][default={}] list of links to show */
	links: any;

	/**[optional][default=false] allow edit the links (true will render edit modal) */
	editable?: boolean;

	/**[optional][default=false] if no links, the social icons in gray color will be shown*/
	showEmptyState?: boolean;

	/**[optional][default=''] additional classname*/
	classname?: string;

	/**[optional][default=true] open social links in new tab*/
	openLinkInNewTab?: boolean;

	/**[optional] required if editable=true. */
	onSocialLinksChange?: (updatedSocialLinks: LinksObject) => void;
}

export function SocialLinks({
	links = {},
	editable = false,
	showEmptyState = false,
	classname = '',
	openLinkInNewTab = true,
	onSocialLinksChange
}: SocialLinksProps) {
	const [isEditLinksModalOpen, setIsEditLinksModalOpen] = useState<boolean>(false);
	const linksList: string[] = Object.keys(links);

	function renderSocialIcon(socialLinkType: string) {
		switch (socialLinkType) {
			case LinkTypes.LINK_TYPE_FACEBOOK:
				return <FacebookIcon />;
			case LinkTypes.LINK_TYPE_GITHUB:
				return <GithubIcon />;
			case LinkTypes.LINK_TYPE_LINKEDIN:
				return <LinkedinIcon />;
			case LinkTypes.LINK_TYPE_TWITTER:
				return <TwitterIcon />;
			case LinkTypes.LINK_TYPE_WEBSITE:
				return <GlobeIcon />;
			default:
				return null;
		}
	}

	return (
		<div className={`social-links ${classname} ${editable ? 'editable-links' : ''}`}>
			{linksList?.length ? (
				<>
					<ul className='links-list'>
						{linksList.map((linkType: string, index) => {
							let link = links[linkType];
							return (
								<li key={`social-link-${index}-${linkType}`}>
									<a href={link} target={openLinkInNewTab ? '_blank' : undefined}>
										{renderSocialIcon(linkType)}
									</a>
								</li>
							);
						})}
					</ul>
				</>
			) : (
				<>
					{showEmptyState ? (
						<ul className='links-list empty-links-list'>
							<li>
								<LinkedinIcon />
							</li>
							<li>
								<FacebookIcon />
							</li>
							<li>
								<TwitterIcon />
							</li>
							<li>
								<GlobeIcon />
							</li>
							<li>
								<GithubIcon />
							</li>
						</ul>
					) : null}
				</>
			)}
			{editable && onSocialLinksChange ? (
				<>
					<a
						className='edit-links-btn'
						role={'button'}
						onClick={() => setIsEditLinksModalOpen(true)}
					>
						<EditIcon />
					</a>
					<EditSocialLinksModal
						isOpen={isEditLinksModalOpen}
						onClose={() => setIsEditLinksModalOpen(false)}
						onSave={(socialLinks: LinksObject) => {
							onSocialLinksChange(socialLinks);
							setIsEditLinksModalOpen(false);
						}}
						socialLinks={links}
					/>
				</>
			) : null}
		</div>
	);
}
