import React, { useState } from 'react';
import { SelectItem } from '../dropdowns/selectUtils';
import { Autocomplete, AutocompleteProps } from '../inputs';
import { TagsCollection } from '../tagCollection';
import { EditableField } from './EditableField';

export interface EditableSelectFieldProps {
  label?: string;
  options?: SelectItem[];
  initialValue: EditableSelectValue;
  onSelectSave: (value: EditableSelectValue) => void;
  selectProps?: AutocompleteProps<SelectItem>;
  multiple?: boolean;
  isHorizontal?: boolean;
}

export type EditableSelectValue = SelectItem | SelectItem[] | null;

export function EditableSelectField(props: EditableSelectFieldProps) {
  const {
    label = '',
    initialValue,
    options = [],
    onSelectSave,
    selectProps,
    multiple = true,
    isHorizontal = false,
  } = props;

  const [value, setValue] = useState<EditableSelectValue>(initialValue);

  const renderContent = (isEditMode: boolean) => {
    if (!isEditMode) {
      if (!Array.isArray(value))
        return <span className="editable-value">{value ? value.value : 'None'}</span>;

      return value.length > 0 ? (
        <TagsCollection items={value} removable={false} />
      ) : (
        <span className="editable-value">None</span>
      );
    }

    return (
      <Autocomplete
        multiple={multiple}
        options={options}
        getOptionLabel={(option: SelectItem | string) =>
          typeof option === 'string' ? option : option.value
        }
        isOptionEqualToValue={(option, value) => option.elId === value.elId}
        value={value}
        onChange={(_e, selected) => setValue(selected as EditableSelectValue)}
        {...selectProps}
      />
    );
  };

  return (
    <EditableField
      label={label}
      onSave={() => onSelectSave(value)}
      onCancel={() => setValue(initialValue)}
      renderContent={renderContent}
      isHorizontal={isHorizontal}
    />
  );
}
