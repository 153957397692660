const formValidations = {
	required: (str: string) => {
		return str?.length;
	},

	isEmail: (str: string) => {
		return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
			.test(str);
	},

	isPhone: (str: string) => {
		return /^05[0-68-9][0-9]{7}$/.test(str);
	},

	max: (str: string, max: number) => {
		return str?.length <= max;
	},

	min: (str: string, min: number) => {
		return str?.length >= min;
	},

	isLink: (str: string) => {
		return /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(
			str
		);
	},
	isLinkedinURLValid(url: string): boolean {
		return /^\s*?(https?:\/\/)?(www\.)?([a-z]{2}\.)?linkedin\.com\/(comm\/)?(in\/|talent\/search\/profile\/|talent\/profile\/)[\w\u0080-\uFFFF%-]+(\/?)(?:\?.*)?(\/|\s*)?$/iu
			.test(url);
	}
};

export default formValidations;
export { formValidations }
