import { Button, EditableText } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useIsMobile } from '../common/utils/hooks/useIsMobile';
import { Select } from '../dropdowns/select/Select';
import { SelectItem } from '../dropdowns/selectUtils';
import { TrashIcon } from '../svg';
import { EditableField } from './EditableField';

export interface SystemField {
  systemId: number;
  systemValue: string;
}

export interface EditableSystemFieldProps {
  label: string;
  initialValues: SystemField[];
  options?: SelectItem[];
  defaultItem?: SystemField;
  onSave?: (data: SystemField[]) => void;
}

export function EditableSystemField({
  label,
  initialValues,
  options = [],
  defaultItem = { systemId: 1, systemValue: '' },
  onSave,
}: EditableSystemFieldProps) {
  const isMobile = useIsMobile();
  const [list, setList] = useState(initialValues);

  return (
    <EditableField
      className={list.length > 1 ? 'editable-values' : ''}
      label={label}
      isHorizontal={!isMobile}
      onSave={() => onSave && onSave(list)}
      onCancel={() => setList(initialValues)}
      renderContent={isEdit => {
        return (
          <div className="editable-content editable-column">
            {list.map((item, index) => {
              const textField = item.systemValue;
              const selectField = options.find(option => option.elId === item.systemId);

              const onChange = (key: string, value: number | string) => {
                const newList = [...list];
                newList[index] = { ...newList[index], [key]: value };
                setList(newList);
              };

              const onRemove = () => {
                const newList = [...list];
                newList.splice(index, 1);
                setList(newList);
              };

              return (
                <div className="editable-grid" key={index}>
                  <EditableText
                    className="editable-value"
                    value={textField}
                    onChange={value => onChange('systemValue', value)}
                    disabled={!isEdit}
                    isEditing={isEdit}
                    placeholder={isEdit || Boolean(textField) ? '' : 'None'}
                  />

                  {isEdit ? (
                    <Select
                      toggleButtonClassName="editable-select"
                      items={options}
                      selectedItem={selectField}
                      onItemSelect={system => onChange('systemId', system.elId)}
                    />
                  ) : (
                    <span className="editable-value">
                      {selectField ? selectField.value : 'None'}
                    </span>
                  )}

                  {isEdit && <Button minimal icon={<TrashIcon />} onClick={onRemove} />}
                </div>
              );
            })}

            {isEdit && (
              <Button
                outlined
                className="add-btn"
                onClick={() => setList(items => [...items, defaultItem])}
              >
                Add
              </Button>
            )}
          </div>
        );
      }}
    />
  );
}
