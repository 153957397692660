import { Icon } from '@blueprintjs/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Box from '@mui/material/Box';
import MuiTab, { TabProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import cn from 'classnames';
import React, { ReactNode, SyntheticEvent, useState } from 'react';

import './tabs.scss';

interface TabData {
  label: string;
  content: ReactNode;
}

export interface TabsProps extends MuiTabsProps {
  data: TabData[];
  tabProps?: TabProps;
  initialIndex?: number;
  onTabChange?: (newTab: number) => void;
  separateMode?: boolean;
}

export function Tabs({ data, tabProps, initialIndex = 0, onTabChange, ...props }: TabsProps) {
  const [value, setValue] = useState(initialIndex);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange?.(newValue);
  };

  return (
    <div className={cn('reusable-tabs', { 'is-vertical': props.orientation === 'vertical' })}>
      <Box className="reusable-tabs-container" sx={{ borderBottom: 1, borderColor: '#EEEFF2' }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          sx={{
            '.MuiTabs-flexContainer': {
              gap: { xs: '24px', sm: '40px' },
              px: { xs: '24px', sm: '40px' },
            },

            '.MuiTabs-indicator': {
              height: '6px',
              backgroundColor: '#307FF6',
            },
          }}
          {...props}
        >
          {data.map((tab, index) => {
            if (Array.isArray(tab.content)) {
              return (
                <TabAccordion
                  key={index}
                  label={tab.label}
                  data={tab.content}
                  tabIndex={index}
                  tabValue={value}
                  onTabChange={handleChange}
                />
              );
            }

            return <TabItem key={index} label={tab.label} {...tabProps} />;
          })}
        </MuiTabs>
      </Box>

      {data.map((tab, index) => {
        if (Array.isArray(tab.content)) {
          return tab.content.map((subTab, subIndex) => (
            <TabPanel key={index + subIndex / 10} index={index + subIndex / 10} value={value}>
              {subTab.content}
            </TabPanel>
          ));
        }

        return (
          <TabPanel key={index} index={index} value={value}>
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
}

interface TabPanelProps {
  index: number;
  value: number;
  children: ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box
          className="tabpanel-container"
          sx={{ padding: { xs: '24px', sm: '40px' }, fontFamily: 'ploni, sans-serif' }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function TabItem({ label, ...props }: TabProps) {
  return (
    <MuiTab
      label={label}
      sx={{
        px: 0,
        py: '14px',
        minWidth: 0,

        fontSize: { xs: 16, sm: 18 },
        fontWeight: 500,
        letterSpacing: 0.36,
        color: '#6C6C6C',
        textTransform: 'none',
        lineHeight: 'normal',
        fontFamily: 'ploni, sans-serif',

        '&.Mui-selected': {
          color: '#2F80ED',
          fontWeight: 600,
        },
      }}
      {...props}
    />
  );
}

interface TabAccordionProps {
  label: string;
  data: TabData[];
  tabIndex: number;
  tabValue: number;
  onTabChange: (_event: SyntheticEvent, newValue: number) => void;
}

const TabAccordion = ({ label, data, tabIndex, tabValue, onTabChange }: TabAccordionProps) => {
  return (
    <Accordion disableGutters elevation={0}>
      <AccordionSummary
        expandIcon={<Icon icon="chevron-down" />}
        onClick={e => onTabChange(e, tabIndex)}
        className={tabIndex === Math.floor(tabValue) ? 'selected' : ''}
      >
        {label}
      </AccordionSummary>

      <AccordionDetails>
        {data.map((tab, index) => (
          <TabItem
            key={index}
            label={tab.label}
            onClick={e => onTabChange(e, tabIndex + index / 10)}
            className={tabIndex + index / 10 === tabValue ? 'selected' : ''}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
