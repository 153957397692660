import React, { HTMLAttributes, ReactNode } from 'react';
import { XIcon } from '../svg/index';

import './listedTag.scss';

export interface ListedTagProps extends HTMLAttributes<HTMLSpanElement> {
	/**[required] tag content */
	children: ReactNode;

	/**[optional][default=false] is tag disabled */
	disabled?: boolean;

	/**[optional][default=''] add classname */
	className?: string;

	/**[optional][default=true] will add a remove btn on the right of the button */
	removable?: boolean;

	/**[optional] execute on click on the remove button */
	onRemove?: () => void;

	/**[optional][default=true] animated tag, important!, will require wrapping the list of tags in <TransitionGroup> component!  */
	animated?: boolean;
}

export function ListedTag({
	children,
	disabled = false,
	className = '',
	removable = true,
	onRemove,
	...props
}: ListedTagProps) {
	return (
		<span
			className={`reusable-listed-tag ${disabled ? 'disabled-listed-tag' : ''} ${
				removable ? 'removable-listed-tag' : ''
			} ${className}`}
			{...props}
		>
			{children}
			{removable && !disabled ? (
				<button className='listed-tag-remove-btn' onClick={onRemove}>
					<XIcon />
				</button>
			) : null}
		</span>
	);
}
