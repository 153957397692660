import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';
import React from 'react';
import { CheckIcon, CheckedIcon } from '../svg';
import InputLabel from './InputLabel';

export interface RadioGroupProps<T> extends MuiRadioGroupProps {
  label?: string;
  items: { label: string; value: T }[];
  controlLabelProps?: FormControlLabelProps;
}

export function RadioGroup<T>({ items, label, controlLabelProps, ...rest }: RadioGroupProps<T>) {
  return (
    <div className="radio-group">
      <InputLabel>{label}</InputLabel>

      <MuiRadioGroup
        row
        {...rest}
        sx={{
          px: '2px',
          gap: '8px',

          '& > *': {
            flexGrow: items.length === 3 ? 1 : 0,
            flexBasis: items.length === 3 ? 'auto' : 'calc(100% / 3 - 16px)',
          },
        }}
      >
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio icon={<CheckIcon />} checkedIcon={<CheckedIcon />} />}
            label={item.label}
            sx={{
              '	.MuiFormControlLabel-label': {
                fontFamily: 'ploni, sans-serif',
                color: '#6C6C6C',
              },
            }}
            {...controlLabelProps}
          />
        ))}
      </MuiRadioGroup>
    </div>
  );
}
