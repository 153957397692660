import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Avatar, AvatarProps } from '../avatar';
import { PencilIcon, TrashIcon } from '../svg';
import { EditableField } from './EditableField';

export interface EditableImageFieldProps {
  label?: string;
  initialImage?: string;
  onImageSave: (text: string) => void;
  onImageError?: () => void;
  avatarProps?: AvatarProps;

  minSize?: number;
  removable?: boolean;
}

export function EditableImageField(props: EditableImageFieldProps) {
  const {
    label = '',
    initialImage = '',
    onImageSave,
    onImageError,
    avatarProps,

    minSize = 1000,
    removable = true,
  } = props;

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;

    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = ev => {
      const fileBase64 = ev.target?.result || '';
      const image = new Image();
      image.src = fileBase64.toString();

      image.onload = function() {
        if (image.height > minSize || image.width > minSize) {
          onImageError && onImageError();
        } else {
          setSelectedImage(fileBase64.toString());
        }
      };
    };

    fileReader.readAsDataURL(file);
  };

  return (
    <EditableField
      label={label}
      onSave={() => selectedImage !== null && onImageSave(selectedImage)}
      onCancel={() => setSelectedImage(null)}
      renderContent={isEdit => {
        return (
          <Box
            sx={{
              display: 'flex',
              // alignItems: 'flex-start',

              label: {
                zIndex: 1,
                // cursor: 'pointer',

                svg: {
                  margin: 0,
                },
              },

              '.MuiAvatar-root': {
                fontSize: '24px',
              },
            }}
          >
            <Avatar size={90} src={selectedImage ?? initialImage} {...avatarProps} />

            {isEdit && (
              <Stack
                sx={{
                  justifyContent: 'space-between',

                  svg: {
                    zIndex: 1,
                    cursor: 'pointer',
                  },
                }}
              >
                <label htmlFor="avatar">
                  <PencilIcon />
                </label>

                {removable && (selectedImage ?? initialImage) && (
                  <TrashIcon onClick={() => setSelectedImage('')} />
                )}
              </Stack>
            )}

            <input id="avatar" hidden type="file" accept="image/*" onChange={handleAvatarChange} />
          </Box>
        );
      }}
    />
  );
}
