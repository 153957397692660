import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled } from '@mui/material/styles';
import cn from 'classnames';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { Loader } from '../loader/Loader';
import { CheckSquareIcon, CheckedSquareIcon, TrashIcon } from '../svg';

import './table.scss';

export type BsTableRowId = number | string;

export interface BsTableProps<T> {
  rows: T[];
  columns: { id: keyof T; label: string }[];
  selectedRow?: BsTableRowId | null;
  defaultOrderBy?: keyof T;

  onRowSelect?: (rowId: BsTableRowId | null) => void;
  onRowRemove?: (rowId: BsTableRowId) => void;
  selectable?: boolean;
  striped?: boolean;
  className?: string;
  disabledSortColumns?: Array<keyof T>;

  collapseBy?: keyof T;
}

export function BsTable<T extends { id: BsTableRowId }>(props: BsTableProps<T>) {
  const {
    rows = [],
    columns = [],
    selectedRow,
    defaultOrderBy,
    onRowSelect,
    onRowRemove,
    selectable = true,
    striped = true,
    className,
    disabledSortColumns = [],

    collapseBy,
  } = props;

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof T>(defaultOrderBy || 'id');

  const isDirty = useRef(false);

  const onRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    isDirty.current = true;
  };

  const sortedRows = useMemo(() => {
    const compareFn = (a: T, b: T) => {
      if (!defaultOrderBy && !isDirty.current) return 0;

      return order === 'desc'
        ? descendingComparator(a, b, orderBy)
        : -descendingComparator(a, b, orderBy);
    };

    return rows.slice().sort(compareFn);
  }, [order, orderBy, rows]);

  const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(even):not(.Mui-selected)': striped ? { backgroundColor: '#F8F8F8' } : {},

    '.MuiTableCell-body': {
      borderWidth: striped ? 0 : 1,
      borderColor: '#EEEFF2',
    },

    '&:last-child .MuiTableCell-body': {
      borderWidth: 0,
    },
  });

  const Row = ({ row }: { row: T }) => {
    const isRowSelected = selectedRow === row.id;

    const keys = Object.keys(row).filter(key => key !== 'id' && key !== collapseBy) as Array<
      keyof T
    >;

    const collapsibleRows = collapseBy ? row[collapseBy] : [];

    const [open, setOpen] = useState(false);

    const hover = selectable || Boolean(onRowSelect);

    return (
      <React.Fragment>
        <StyledTableRow
          hover={hover}
          selected={isRowSelected}
          sx={{ cursor: hover ? 'pointer' : 'auto' }}
          onClick={() => onRowSelect?.(isRowSelected ? null : row.id)}
        >
          {selectable && (
            <StyledTableCell padding="checkbox">
              <Checkbox
                checked={isRowSelected}
                icon={<CheckSquareIcon />}
                checkedIcon={<CheckedSquareIcon />}
              />
            </StyledTableCell>
          )}

          {keys.map((key, index) => (
            <StyledTableCell
              className={`${String(key)}-cell`}
              key={key as string | number}
              {...(index === 0 ? { component: 'th', scope: 'row' } : {})}
            >
              {row[key]}
            </StyledTableCell>
          ))}

          {onRowRemove && (
            <StyledTableCell align="right">
              <span
                onClick={() => {
                  // e.stopPropagation();
                  onRowRemove(row.id);
                }}
              >
                <TrashIcon />
              </span>
            </StyledTableCell>
          )}

          {collapseBy && (
            <TableCell align="right">
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? (
                  <KeyboardArrowUpRoundedIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownRoundedIcon fontSize="small" />
                )}
              </IconButton>
            </TableCell>
          )}
        </StyledTableRow>

        {collapseBy && (
          <TableRow>
            <TableCell sx={{ padding: 0 }} colSpan={88}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table
                  // size="small"
                  className="collapsible-table"
                  sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
                >
                  <TableHead></TableHead>

                  <TableBody>
                    {Array.isArray(collapsibleRows) &&
                      collapsibleRows.map((row, idx) => (
                        <StyledTableRow key={idx}>
                          {Object.keys(row).map((key, index) => (
                            <StyledTableCell
                              className={`${String(key)}-cell`}
                              key={key as string | number}
                              {...(index === 0 ? { component: 'th', scope: 'row' } : {})}
                            >
                              {row[key]}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  return (
    <TableContainer className={cn('bs-table', className)} sx={{ backgroundColor: 'white' }}>
      <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
        <TableHead>
          <TableRow>
            {selectable && <TableCell padding="checkbox"></TableCell>}

            {columns.map(column => {
              return (
                <StyledTableCell
                  key={column.id as string | number}
                  className={`${String(column.id)}-cell`}
                >
                  {column.label && !disabledSortColumns.includes(column.id) && (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      onClick={() => onRequestSort(column.id)}
                      sx={{
                        '&.Mui-active .MuiTableSortLabel-icon': { color: '#6C6C6C' },
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}

                  {column.label && disabledSortColumns.includes(column.id) && column.label}
                </StyledTableCell>
              );
            })}

            {onRowRemove && <TableCell align="right" className="trash-cell"></TableCell>}

            {collapseBy && <TableCell align="right" className="collapsible-cell"></TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedRows.map(row => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;

  return 0;
}

const StyledTableCell = styled(TableCell)({
  '&.MuiTableCell-head': {
    fontFamily: 'ploni, sans-serif',
    fontSize: 16,
    fontWeight: 500,
    color: '#404040',

    padding: '18px 16px',
  },

  '&.MuiTableCell-body': {
    fontFamily: 'ploni, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    color: '#6C6C6C',

    // border: 0,
  },
});

export function BsTableLoading({ ...props }) {
  return (
    <div className="bs-table-content" {...props}>
      <Loader />
    </div>
  );
}

export function BsTableNoContent({ children, ...props }: { children: ReactNode }) {
  return (
    <div className="bs-table-content" {...props}>
      {children ?? <h4>No data</h4>}
    </div>
  );
}
