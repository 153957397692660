import { FormLabel } from '@mui/material';
import Collapse, { CollapseProps } from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import cn from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { CollapseIcon, ExpandIcon } from '../svg';

export interface InputCollapseProps extends CollapseProps {
  label: string;
  children: ReactNode;
  defaultExpanded?: boolean;
  disabled?: boolean;
  disableCollapse?: boolean;
}

export function InputCollapse({
  label,
  children,
  defaultExpanded = false,
  disabled = false,
  disableCollapse = false,
  ...rest
}: InputCollapseProps) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const isExpanded = (defaultExpanded && !disabled) || disableCollapse;
    setExpanded(isExpanded);
  }, [disabled, defaultExpanded, disableCollapse]);

  return (
    <div className={cn('input-collapse', { expanded })}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        mb={expanded ? 1 : 0}
        className="input-collapse-header"
      >
        <FormLabel
          className="input-collapse-label"
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: '#6C6C6C',
            letterSpacing: 'normal',
            lineHeight: 'normal',
            fontFamily: 'ploni, sans-serif',
          }}
          disabled={disabled}
        >
          {label}
        </FormLabel>

        {!disableCollapse && (
          <IconButton onClick={() => setExpanded(x => !x)} disabled={disabled}>
            {expanded ? (
              <CollapseIcon />
            ) : (
              <ExpandIcon fill={disabled ? 'rgba(0, 0, 0, 0.26)' : '#6C6C6C'} />
            )}
          </IconButton>
        )}
      </Stack>

      <Collapse in={expanded} timeout={150} {...rest}>
        {children}
      </Collapse>
    </div>
  );
}
