import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { Select as bpSelect, IItemRendererProps } from '@blueprintjs/select';
import { v4 } from 'uuid';

import { generateHtmlForId } from '../../common/utils/utilFunctions';
import { SelectDropdownItem } from '../selectDropdownItem/SelectDropdownItem';
import { SelectItem, SelectMenuProps } from '../selectUtils';
import { SolidSelectArrowDown, SelectArrowDown } from '../../svg/index';
import { FormControlProps } from '../..';
import { Label } from '../../label/Label';

import './select.scss';

export interface SelectProps extends HTMLAttributes<HTMLElement>, FormControlProps, SelectMenuProps {
	/**[optional] The selected item will be highlighted in the select menu, and it's value will be displayed at the toggle button*/
	selectedItem?: SelectItem;

	/**[optional][default='default'] define the general styling. */
	styleType?: 'solid' | 'default';

	/**[optional][default=42] minimum width of the toggle select button */
	minToggleButtonWidth?: number;

	/**[optional][default=false] allow filtering the items*/
	filterable?: boolean;

	/**[optional][default='filter'] control the filter input placeholder */
	filterableInputPlaceholder?: string;

	/**[optional] custom toggle button content renderer*/
	toggleButtonContentRenderer?: (item: SelectItem) => ReactNode;

	/**[optional] icon to be rendered left side of the toggle button content */
	toggleButtonIcon?: ReactNode;

	/**[optional][default=""] classname to be added to the toggle button:
	 * classnames: alert-toggle-button, warning-toggle-button, confirm-toggle-button
	 */
	toggleButtonClassName?: string;
}

export const SelectOfType = bpSelect.ofType<SelectItem>();

export function Select({
	items = [],
	onItemSelect,
	disabled = false,
	selectedItem,
	placeholder = 'Select item',
	styleType = 'default',
	minToggleButtonWidth = 42,
	label = '',
	hiddenLabel = false,
	labelIcon,
	id,
	fillContainerWidth = true,
	inline = false,
	filterable = false,
	filterableInputPlaceholder = 'Filter...',
	query,
	onQueryTextChange,
	showMenuImages = false,
	menuItemImgRenderer,
	toggleButtonContentRenderer,
	toggleButtonIcon,
	toggleButtonClassName = '',
	error
}: SelectProps) {
	const [currentQuery, setCurrentQuery] = useState<string>('');
	const [lastSearchText, setLastSearchText] = useState<string>('');

	const generatedId = id ? id : generateHtmlForId(label);

	const itemPredicate = (item: any, cat: any) => {
		if (cat?.name && cat?.value) {
			const text = `${cat.name} ${cat.value}`.toLowerCase();
			return text.indexOf(item?.toLowerCase()) >= 0;
		}
		return cat?.value?.toLowerCase().indexOf(item?.toLowerCase()) >= 0;
	};

	function renderSelectItem(item: SelectItem, itemProps: IItemRendererProps) {
		return (
			<SelectDropdownItem
				key={`menuItem_select_${item?.elId}_${v4()}`}
				item={item}
				itemProps={itemProps}
				styleType={styleType}
				query={itemProps.query}
				withHighlightedQueryText={true}
				showMenuImages={showMenuImages}
				imageCustomRenderer={menuItemImgRenderer}
			/>
		);
	}

	function areItemsEqual(itemA: SelectItem, itemB: SelectItem) {
		return itemA.elId === itemB.elId;
	}

	const renderNoResults: ReactNode = (
		<SelectDropdownItem
			key={`menuItem_select_noresult_${v4()}`}
			query={currentQuery}
			noResultItem={true}
			allowCreateNewItem={false}
			styleType={styleType}
		/>
	);

	const onQueryChange = (text: string) => {
		setCurrentQuery(text);
		if (query && lastSearchText !== text) {
			setLastSearchText(text);
			query(text);

			onQueryTextChange && onQueryTextChange(text);
		}
	};

	return (
		<div
			className={`select-wrapper common-form-control ${styleType}-style-type ${
				inline ? 'inline-element' : ''
			} ${error ? 'has-error' : ''}`}
		>
			<Label
				text={label}
				hidden={hiddenLabel}
				icon={labelIcon}
				htmlFor={generatedId}
				inline={inline}
				disabled={disabled}
			/>
			<SelectOfType
				fill={fillContainerWidth}
				scrollToActiveItem={true}
				items={items}
				filterable={filterable}
				matchTargetWidth={true}
				popoverProps={{ modifiers: { arrow: { enabled: false } } }}
				onItemSelect={onItemSelect}
				itemPredicate={itemPredicate}
				itemRenderer={renderSelectItem}
				disabled={disabled}
				activeItem={selectedItem}
				itemsEqual={areItemsEqual}
				inputProps={{ placeholder: filterableInputPlaceholder }}
				noResults={renderNoResults}
				onQueryChange={onQueryChange}
			>
				<button
					id={generatedId}
					style={{ minWidth: `${minToggleButtonWidth}px` }}
					className={`select-toggle-button control-body ${
						disabled ? 'disabled-select-toggle-button ' : ''
					} ${selectedItem ? '' : 'placeholder-button'} ${toggleButtonClassName}`}
					type='button'
					role='list'
				>
					{toggleButtonIcon ? toggleButtonIcon : null}
					{selectedItem
						? toggleButtonContentRenderer
							? toggleButtonContentRenderer(selectedItem)
							: selectedItem.value
						: placeholder}
					{styleType === 'default' ? <SelectArrowDown /> : <SolidSelectArrowDown />}
				</button>
			</SelectOfType>
			{error && <p className={'control-error-message'}>{error}</p>}
		</div>
	);
}
