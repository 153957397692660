import React from 'react';
import moment from 'moment';
import { DateInput as BpDateInput } from '@blueprintjs/datetime';
import { XIcon } from '../../svg';
import { FormControlProps } from '../../common/utils/formUtils';
import { generateHtmlForId } from '../../common/utils/utilFunctions';
import './dateInput.scss';
import '../../common/formControl.scss';
import InputLabel from '../InputLabel';

export interface DateInputProps extends FormControlProps {
	/**[required] initial date to be displayed js Date */
	value: Date | null;

	/**[required] on date change function */
	onChange: (date: Date | null) => void;

	/**[optional][default=true] allow reseting the control data */
	allowReset?: boolean;

	/**[optional] on reset function to be executed on reset button click */
	onReset?: () => void;

	/**[optional][default=DD/MM/YYYY] date format to be displayed in the input*/
	format?: string;

	/**[optional][default=true] input will fill the container width*/
	fillContainerWidth?: boolean;

	/**[optional][default=false] show action bar th the bottom of the datePicker - with clear button and shortcut for today's date */
	showActionsBar?: boolean;

	/**[optional][default=false] show dates shortcuts panel at the left*/
	shortcuts?: boolean;

	/**[optional] [default= new Date('1970')]*/
	minDate?: Date;

	/**[optional] [default= new Date() - now]*/
	maxDate?: Date;

	rightIcon?: JSX.Element
}

export function DateInput({
	value,
	onChange,
	format = 'DD/MM/YYYY',
	placeholder = 'DD/MM/YYYY',
	fillContainerWidth = true,
	showActionsBar = false,
	shortcuts = false,
	minDate = new Date('1970'),
	maxDate = new Date(),
	className = '',
	inline = false,
	allowReset = true,
	onReset,
	hiddenLabel = false,
	label = '',
	id,
	disabled,
	error,
	rightIcon,
}: DateInputProps) {
	const generatedId = id ? id : generateHtmlForId(label);

	const resetButton =
		allowReset && onReset && value ? (
			<a role='button' className='reset-button' onClick={() => onReset()}>
				<XIcon />
			</a>
		) : (
			rightIcon
		);

	return (
		<div
			className={`date-input common-form-control ${inline ? 'inline-element' : ''} ${
				error ? 'has-error' : ''
			} ${className}`}
		>
			{label &&
				<InputLabel hidden={hiddenLabel} htmlFor={generatedId} disabled={disabled}>
					{label}
				</InputLabel>
			}
			<BpDateInput
				value={value}
				inputProps={{ rightElement: resetButton, id: generatedId }}
				locale='en'
				disabled={disabled}
				canClearSelection={true}
				formatDate={(date: Date) => formatDateToString(date, format)}
				parseDate={(str: string) => formatStringToDate(str, format)}
				placeholder={placeholder}
				onChange={(date: Date | null) => onChange(date)}
				defaultValue={new Date()}
				fill={fillContainerWidth}
				popoverProps={{ usePortal: false, modifiers: { arrow: { enabled: false } } }}
				showActionsBar={showActionsBar}
				shortcuts={shortcuts}
				clearButtonText={'clear'}
				todayButtonText={'Today'}
				reverseMonthAndYearMenus={false}
				highlightCurrentDay={true}
				minDate={minDate}
				maxDate={maxDate}
			/>
			{error && <p className={'control-error-message'}>{error}</p>}
		</div>
	);
}

export function formatDateToString(date: Date, format: string) {
	return moment(date).format(format);
}

export function formatStringToDate(dateString: string, format: string) {
	return moment(dateString, format).toDate();
}
