import React, { HTMLAttributes, ReactNode } from 'react';

import './label.scss';

export interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
	/**[required] label text */
	text?: string;

	/**[optional][default=false] label will show up as hidden - for screen readers. */
	hidden?: boolean;

	/**[optional] icon to render left to the label text */
	icon?: ReactNode;

	/**[optional][recommended] htmlFor attribute to get the target element focused on label clicked. recommended for accessibility */
	htmlFor?: string;

	/**[optional][default=false] label element as inline element */
	inline?: boolean;

	/**[optional][default=false] is label belongs to a disabeld form control */
	disabled?: boolean;
}

export function Label({
	text = '',
	hidden = false,
	icon,
	htmlFor,
	inline = false,
	className = '',
	disabled = false,
	...props
}: LabelProps) {
	if (!(text || icon)) {
		return null;
	}

	return (
		<label
			htmlFor={htmlFor ?? ''}
			className={`BS-label 
        ${className ?? ''} 
        ${hidden && !icon ? 'hidden-label' : ''} 
        ${inline ? 'inline-label' : ''}
        ${disabled ? 'disabled-label' : ''}`}
			{...props}
		>
			{icon && <span className='label-icon'>{icon}</span>}
			{text && <span className={`label-text ${hidden ? 'hidden-label-text' : ''}`}>{text}</span>}
		</label>
	);
}
