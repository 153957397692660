import React, { ReactNode } from 'react';
import { SelectItem, highlightText } from '../selectUtils';
import { MenuItem } from '@blueprintjs/core';
import { FocusStyleManager } from '@blueprintjs/core';

import './selectDropdownItem.scss';

FocusStyleManager.onlyShowFocusOnTabs();

export interface SelectDropdownItemProps {
	item?: SelectItem;
	itemProps?: any;
	className?: string;
	activeClassName?: string;
	styleType?: 'solid' | 'default';
	withHighlightedQueryText?: boolean;
	query?: string;
	noResultItem?: boolean;
	allowCreateNewItem?: boolean;
	createNewItemFromQuery?: (query: string) => void;
	showMenuImages?: boolean;
	imageCustomRenderer?: (item: SelectItem | any) => ReactNode;
	shouldDismissPopover?: boolean;
	customTextRenderer?: (item: SelectItem) => void;
}

export function SelectDropdownItem({
	item,
	itemProps,
	className = '',
	activeClassName = 'active-select-item',
	styleType,
	withHighlightedQueryText,
	query,
	noResultItem = false,
	allowCreateNewItem,
	createNewItemFromQuery,
	showMenuImages = false,
	imageCustomRenderer,
	shouldDismissPopover = true
}: SelectDropdownItemProps) {
	if (!noResultItem) {
		if (!itemProps.modifiers.matchesPredicate) {
			return null;
		}
	}

	const text = `${item?.value}`;

	const highlitedText = withHighlightedQueryText && query ? highlightText(text, query) : text;

	const imgChild = (
		<>
			<div className='item-image-wrapper'>
				{imageCustomRenderer ? (
					imageCustomRenderer(item)
				) : item?.img ? (
					<img src={item?.img} alt={`image of ${item?.value}`} />
				) : (
					<div className='item-image-placeholder'></div>
				)}
			</div>
			{highlitedText}
		</>
	);

	function renderNoResultItem() {
		return (
			<MenuItem
				icon={allowCreateNewItem ? 'add' : 'issue'}
				active={false}
				className={`select-dropdown-item no-results-item ${className} ${styleType}SelectDropdownItemStyleType`}
				shouldDismissPopover={shouldDismissPopover}
				text={allowCreateNewItem ? `no results, Create ${query}` : 'No results found'}
				onClick={(event: any) => {
					event.stopPropagation();
					createNewItemFromQuery && allowCreateNewItem && query && createNewItemFromQuery(query);
				}}
			/>
		);
	}

	if ((item?.elId == null || !item?.value) && !noResultItem) {
		console.error('missing elId or value in SelectDropdownItem');
	}

	return noResultItem ? (
		renderNoResultItem()
	) : item?.elId == null || !item?.value ? null : (
		<MenuItem
			active={itemProps?.modifiers?.active}
			shouldDismissPopover={shouldDismissPopover}
			className={`select-dropdown-item
          ${className}
          ${itemProps?.modifiers?.active ? activeClassName : ''}
          ${styleType}-select-dropdown-item-style-type
          ${showMenuImages ? 'select-item-with-image ' : ''}`}
			text={showMenuImages ? imgChild : highlitedText}
			multiline={true}
			onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
			onClick={(event: any) => {
				event.stopPropagation();
				itemProps?.handleClick(event);
			}}
		/>
	);
}
