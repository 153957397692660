import { Spinner } from '@blueprintjs/core';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import './button.scss';

export interface ButtonProps {
	/**[required] Provide a text or child elements for the button, will be ignored if 'text' prop is used */
	children?: ReactNode;

	/**[optional][default: 'primary'] Button variant*/
	variant?: 'primary' | 'secondary' | 'danger' | 'marketing';

	/**[optional] Type of a button, will be ignored if 'elementToRender' prop is not 'button' */
	type?: 'button' | 'submit' | 'reset';

	/**[optional][default: 'button'] the html element that will be rendered: 'button' = <button> | 'link' = <Link> | 'anchor' = <a>*/
	elementToRender?: 'button' | 'link' | 'anchor';

	/**[optional][default: '#'] in case elementToRender='link'|| 'anchor' */
	href?: string;

	/**[optional] OnClick method */
	onClick?: (event?: any) => void;

	/**[optional] define role for accecability. */
	role?: string;

	/**[optional][default: false] is button disabled, relevant to elementToRender='button' only */
	disabled?: boolean;

	/**[optional] chain extra classnames */
	classNames?: string;

	/**[optional] loading state to button */
	isLoading?: boolean;
}

/**
 * @deprecated Please use ButtonV2
 */
/** reusable Button component */
export function Button({
	children,
	variant = 'primary',
	type = 'button',
	elementToRender = 'button',
	href = '#',
	onClick,
	role = undefined,
	disabled = false,
	classNames = '',
	isLoading,
	...props
}: ButtonProps) {
	let className = `reusable-button ${variant}-button ${elementToRender}-element ${
		isLoading ? 'loading-button' : ''
	} ${classNames}`;

	function renderLoadingState() {
		return <Spinner size={25} intent={'none'} />;
	}

	function renderButton() {
		return (
			<button
				{...props}
				className={className}
				role={role}
				disabled={disabled}
				type={type}
				onClick={onClick}
			>
				{isLoading ? renderLoadingState() : children}
			</button>
		);
	}

	function renderAnchorButton() {
		return (
			<a {...props} className={className} role={role} onClick={onClick} href={href}>
				{isLoading ? renderLoadingState() : children}
			</a>
		);
	}

	function renderLinkButton() {
		return (
			<Link {...props} className={className} to={href} role={role}>
				{isLoading ? renderLoadingState() : children}
			</Link>
		);
	}

	switch (elementToRender) {
		case 'button':
			return renderButton();
		case 'anchor':
			return renderAnchorButton();
		case 'link':
			return renderLinkButton();
		default:
			return renderButton();
	}
}
