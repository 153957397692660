import React, {ComponentProps} from 'react';
import classNames from "classnames";
import './Card.scss';

export type CardProps = ComponentProps<'div'>;

export function Card({ children, className, ...rest }: CardProps) {
  return (
    <div className={classNames("card", className)} {...rest}>
      {children}
    </div>
  );
}

export function CardHeader({ children, className, ...rest }: CardProps) {
  return <div className={classNames('card-header text-heading', className)} {...rest}>{children}</div>
}


export function CardContent({ children, className, ...rest }: CardProps) {
  return <div className={classNames('card-content', className)} {...rest}>{children}</div>
}

